// src/components/meetings/AgendaForm.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Plus, X, Save, Clock, User, Edit, Trash2, ChevronDown, 
  ChevronUp, FileText, Link, ArrowRight, ArrowLeft
} from 'lucide-react';
import { format } from 'date-fns';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  serverTimestamp 
} from 'firebase/firestore';

const AgendaForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  
  const [meeting, setMeeting] = useState(null);
  const [agendaItems, setAgendaItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [expandedItem, setExpandedItem] = useState(null);
  const [isEditing, setIsEditing] = useState({});

  // Fetch meeting and agenda data
  useEffect(() => {
    const fetchMeeting = async () => {
      if (!id) return;
      
      setIsLoading(true);
      try {
        const meetingDoc = await getDoc(doc(db, "meetings", id));
        
        if (meetingDoc.exists()) {
          const data = meetingDoc.data();
          const meetingData = {
            id: meetingDoc.id,
            ...data,
            date: data.date?.toDate() || null,
            endTime: data.endTime?.toDate() || null,
            createdAt: data.createdAt?.toDate() || null,
            updatedAt: data.updatedAt?.toDate() || null
          };
          
          setMeeting(meetingData);
          
          // Initialize agenda items from meeting data or with empty array
          if (Array.isArray(meetingData.agendaItems) && meetingData.agendaItems.length > 0) {
            setAgendaItems(meetingData.agendaItems);
          } else {
            setAgendaItems([{ id: `a${Date.now()}`, title: '', description: '', duration: 15, presenter: '', relatedTo: null }]);
          }
        } else {
          setError("Meeting not found");
        }
      } catch (err) {
        console.error("Error fetching meeting:", err);
        setError("Failed to load meeting data");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchMeeting();
  }, [id]);

  // Mock data for references
  const mockReferences = {
    structure: [
      { id: 'str1', name: 'Academic Committee' },
      { id: 'str2', name: 'Disciplinary Committee' },
      { id: 'str3', name: 'School Management Team' },
      { id: 'str4', name: 'Student Representative Council' }
    ],
    policy: [
      { id: 'pol1', name: 'Financial Policy' },
      { id: 'pol2', name: 'Admission Policy' },
      { id: 'pol3', name: 'Code of Conduct' },
      { id: 'pol4', name: 'Assessment Policy' }
    ],
    plan: [
      { id: 'pln1', name: 'Annual Teaching Plan' },
      { id: 'pln2', name: 'School Improvement Plan' },
      { id: 'pln3', name: 'Budget Plan' },
      { id: 'pln4', name: 'Academic Recovery Plan' }
    ],
    project: [
      { id: 'prj1', name: 'Staff Development Program' },
      { id: 'prj2', name: 'School Fundraising Initiative' },
      { id: 'prj3', name: 'Infrastructure Upgrade' },
      { id: 'prj4', name: 'Academic Excellence Program' }
    ],
    document: [
      { id: 'doc1', name: 'Previous Minutes' },
      { id: 'doc2', name: 'Budget Report' },
      { id: 'doc3', name: 'Academic Performance Report' },
      { id: 'doc4', name: 'School Calendar' }
    ]
  };

  // Add a new agenda item
  const addAgendaItem = () => {
    const newItem = {
      id: `a${Date.now()}`,
      title: '',
      description: '',
      duration: 15,
      presenter: '',
      relatedTo: null
    };
    
    setAgendaItems([...agendaItems, newItem]);
    setExpandedItem(newItem.id);
    setIsEditing({ ...isEditing, [newItem.id]: true });
  };

  // Remove an agenda item
  const removeAgendaItem = (itemId) => {
    setAgendaItems(agendaItems.filter(item => item.id !== itemId));
    if (expandedItem === itemId) {
      setExpandedItem(null);
    }
  };

  // Update an agenda item
  const updateAgendaItem = (itemId, field, value) => {
    setAgendaItems(agendaItems.map(item => 
      item.id === itemId ? { ...item, [field]: value } : item
    ));
  };

  // Set related reference for an agenda item
  const setItemReference = (itemId, type, referenceId) => {
    const reference = mockReferences[type].find(ref => ref.id === referenceId);
    
    if (reference) {
      setAgendaItems(agendaItems.map(item => 
        item.id === itemId ? { 
          ...item, 
          relatedTo: { type, id: referenceId, name: reference.name } 
        } : item
      ));
    }
  };

  // Remove related reference from an agenda item
  const removeItemReference = (itemId) => {
    setAgendaItems(agendaItems.map(item => 
      item.id === itemId ? { ...item, relatedTo: null } : item
    ));
  };

  // Save agenda
  const handleSave = async () => {
    // Validate agenda items
    if (agendaItems.some(item => !item.title)) {
      setError('All agenda items must have a title');
      return;
    }

    if (!meeting) {
      setError('Meeting data not available');
      return;
    }

    try {
      setIsLoading(true);
      setError('');
      
      // Update meeting with new agenda items
      await updateDoc(doc(db, "meetings", id), {
        agendaItems: agendaItems,
        updatedAt: serverTimestamp()
      });
      
      setSuccess('Agenda saved successfully');
      
      // Navigate back after a short delay
      setTimeout(() => {
        navigate(`/meetings/details/${id}`);
      }, 1500);
      
    } catch (err) {
      console.error('Error saving agenda:', err);
      setError('Failed to save agenda: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Calculate total meeting duration
  const getTotalDuration = () => {
    return agendaItems.reduce((total, item) => total + (parseInt(item.duration) || 0), 0);
  };

  // Format the total duration in hours and minutes
  const formatTotalDuration = () => {
    const totalMinutes = getTotalDuration();
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    
    if (hours === 0) return `${minutes} minutes`;
    if (minutes === 0) return `${hours} hour${hours > 1 ? 's' : ''}`;
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minutes`;
  };

  // Toggle expanded/collapsed state of an item
  const toggleExpandItem = (itemId) => {
    setExpandedItem(expandedItem === itemId ? null : itemId);
  };
  
  // Toggle edit mode for an item
  const toggleEditItem = (itemId) => {
    setIsEditing({ 
      ...isEditing, 
      [itemId]: !isEditing[itemId] 
    });
  };

  // Get available presenters from meeting participants
  const getAvailablePresenters = () => {
    const presenters = [];
    
    // Add meeting chairperson if available
    if (meeting?.chairperson) {
      presenters.push(meeting.chairperson);
    }
    
    // Add meeting secretary if available
    if (meeting?.secretary) {
      presenters.push(meeting.secretary);
    }
    
    // Add meeting participants' names
    if (Array.isArray(meeting?.participants) && meeting.participants.length > 0) {
      meeting.participants.forEach(participant => {
        // Handle both string and object participants
        const name = typeof participant === 'string' ? participant : participant.name;
        if (name && !presenters.includes(name)) {
          presenters.push(name);
        }
      });
    }
    
    return presenters;
  };

  // Calculate the time for each agenda item based on meeting start time
  const getItemTime = (index) => {
    if (!meeting?.date) return '';
    
    const startTime = new Date(meeting.date);
    let minutesToAdd = 0;
    
    for (let i = 0; i < index; i++) {
      minutesToAdd += parseInt(agendaItems[i].duration) || 0;
    }
    
    const itemTime = new Date(startTime.getTime() + minutesToAdd * 60000);
    return format(itemTime, 'h:mm a');
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        <p className="ml-2">Loading agenda...</p>
      </div>
    );
  }

  if (error && !meeting) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex">
            <p className="text-red-800">{error}</p>
          </div>
        </div>
        <button
          onClick={() => navigate('/meetings')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Meetings
        </button>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">Meeting Agenda</h1>
          {meeting && (
            <p className="text-sm text-gray-500">
              {meeting.title} - {meeting.date ? format(new Date(meeting.date), 'MMMM d, yyyy') : 'Date not set'}
            </p>
          )}
        </div>
        
        <div className="mt-4 md:mt-0 flex items-center space-x-3">
          <button
            onClick={() => navigate(`/meetings/details/${id}`)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Meeting
          </button>
          
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleSave}
            disabled={isLoading}
          >
            <Save className="h-4 w-4 mr-2" />
            {isLoading ? 'Saving...' : 'Save Agenda'}
          </button>
        </div>
      </div>
      
      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
      
      {success && (
        <div className="mb-6 bg-green-50 border-l-4 border-green-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700">{success}</p>
            </div>
          </div>
        </div>
      )}
      
      {/* Summary Card */}
      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-lg font-medium text-gray-900">Summary</h3>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <Clock className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              <p>
                {meeting?.date ? format(new Date(meeting.date), 'h:mm a') : 'Time not set'} - 
                {meeting?.endTime ? format(new Date(meeting.endTime), ' h:mm a') : ''}
                <span className="ml-1">
                  (Duration: {formatTotalDuration()})
                </span>
              </p>
            </div>
          </div>
          <div>
            <div className="text-sm text-gray-500">
              <div className="mb-1">Total Items: {agendaItems.length}</div>
              <div>Items to discuss: {agendaItems.filter(item => item.title).length}</div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Agenda Items */}
      <div className="bg-white shadow rounded-lg">
        <div className="p-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Agenda Items</h3>
        </div>
        
        <div className="divide-y divide-gray-200">
          {agendaItems.length === 0 ? (
            <div className="text-center py-12">
              <FileText className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No agenda items</h3>
              <p className="mt-1 text-sm text-gray-500">Add items to your agenda to get started.</p>
            </div>
          ) : (
            agendaItems.map((item, index) => (
              <div key={item.id} className="py-4 px-6">
                {/* Item Header - Always Visible */}
                <div className="flex items-center justify-between" onClick={() => toggleExpandItem(item.id)}>
                  <div className="flex items-center flex-1 cursor-pointer">
                    <div className="min-w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium mr-4">
                      {index + 1}
                    </div>
                    
                    {isEditing[item.id] ? (
                      <input
                        type="text"
                        className="flex-1 border-b border-gray-300 py-1 px-0 focus:outline-none focus:ring-0 focus:border-blue-500"
                        value={item.title}
                        onChange={(e) => updateAgendaItem(item.id, 'title', e.target.value)}
                        placeholder="Enter agenda item title"
                        onClick={(e) => e.stopPropagation()}
                      />
                    ) : (
                      <div className="flex-1">
                        <h4 className="text-base font-medium text-gray-900">
                          {item.title || <span className="text-gray-400 italic">Untitled Item</span>}
                        </h4>
                        <div className="flex items-center text-xs text-gray-500 mt-1">
                          <Clock className="h-3 w-3 mr-1" /> 
                          {getItemTime(index)} • {item.duration} mins
                          {item.presenter && (
                            <>
                              <span className="mx-1">•</span>
                              <User className="h-3 w-3 mr-1" /> 
                              {item.presenter}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  
                  <div className="flex items-center ml-4">
                    {/* Action Buttons */}
                    <button 
                      type="button"
                      className="text-gray-400 hover:text-blue-500 p-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleEditItem(item.id);
                      }}
                    >
                      <Edit className="h-4 w-4" />
                    </button>
                    
                    <button 
                      type="button"
                      className="text-gray-400 hover:text-red-500 p-1 ml-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeAgendaItem(item.id);
                      }}
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                    
                    <button
                      type="button"
                      className="text-gray-400 p-1 ml-1"
                      onClick={() => toggleExpandItem(item.id)}
                    >
                      {expandedItem === item.id ? (
                        <ChevronUp className="h-5 w-5" />
                      ) : (
                        <ChevronDown className="h-5 w-5" />
                      )}
                    </button>
                  </div>
                </div>
                
                {/* Expanded Item Details */}
                {expandedItem === item.id && (
                  <div className="mt-4 pl-12 pr-4">
                    <div className="space-y-4">
                      {/* Description */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Description
                        </label>
                        {isEditing[item.id] ? (
                          <textarea
                            className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            value={item.description || ''}
                            onChange={(e) => updateAgendaItem(item.id, 'description', e.target.value)}
                            rows={3}
                          />
                        ) : (
                          <p className="text-sm text-gray-600">
                            {item.description || <span className="text-gray-400 italic">No description provided</span>}
                          </p>
                        )}
                      </div>
                      
                      {/* Duration and Presenter */}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Duration (minutes)
                          </label>
                          {isEditing[item.id] ? (
                            <input
                              type="number"
                              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              value={item.duration}
                              onChange={(e) => updateAgendaItem(item.id, 'duration', parseInt(e.target.value) || 0)}
                              min="1"
                            />
                          ) : (
                            <p className="text-sm text-gray-600">{item.duration} minutes</p>
                          )}
                        </div>
                        
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Presenter
                          </label>
                          {isEditing[item.id] ? (
                            <select
                              className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              value={item.presenter || ''}
                              onChange={(e) => updateAgendaItem(item.id, 'presenter', e.target.value)}
                            >
                              <option value="">Select presenter</option>
                              {getAvailablePresenters().map(presenter => (
                                <option key={presenter} value={presenter}>{presenter}</option>
                              ))}
                            </select>
                          ) : (
                            <p className="text-sm text-gray-600">
                              {item.presenter || <span className="text-gray-400 italic">Not assigned</span>}
                            </p>
                          )}
                        </div>
                      </div>
                      
                      {/* References */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Related Resource
                        </label>
                        
                        {isEditing[item.id] ? (
                          <div className="space-y-2">
                            {item.relatedTo ? (
                              <div className="flex items-center justify-between bg-gray-50 p-2 rounded">
                                <div className="flex items-center">
                                  <Link className="h-4 w-4 text-blue-500 mr-2" />
                                  <span className="text-sm">
                                    {item.relatedTo.type.charAt(0).toUpperCase() + item.relatedTo.type.slice(1)}: {item.relatedTo.name}
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  className="text-red-500 hover:text-red-700"
                                  onClick={() => removeItemReference(item.id)}
                                >
                                  <X className="h-4 w-4" />
                                </button>
                              </div>
                            ) : (
                              <div className="grid grid-cols-2 gap-2">
                                {Object.keys(mockReferences).map(type => (
                                  <div key={type} className="mb-2">
                                    <h4 className="text-xs font-medium text-gray-500 uppercase mb-1">
                                      {type.charAt(0).toUpperCase() + type.slice(1)}s
                                    </h4>
                                    {mockReferences[type].map(ref => (
                                      <button
                                        key={ref.id}
                                        type="button"
                                        className="flex items-center w-full text-left px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 rounded"
                                        onClick={() => setItemReference(item.id, type, ref.id)}
                                      >
                                        <ArrowRight className="h-3 w-3 mr-1 text-gray-400" />
                                        {ref.name}
                                      </button>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <p className="text-sm text-gray-600">
                            {item.relatedTo ? (
                              <span className="flex items-center">
                                <Link className="h-4 w-4 text-blue-500 mr-2" />
                                {item.relatedTo.type.charAt(0).toUpperCase() + item.relatedTo.type.slice(1)}: {item.relatedTo.name}
                              </span>
                            ) : (
                              <span className="text-gray-400 italic">No references</span>
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
        
        {/* Add Item Button */}
        <div className="p-6 border-t border-gray-200">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={addAgendaItem}
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Agenda Item
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgendaForm;