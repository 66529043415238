// src/components/meetings/MeetingDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  Calendar, Clock, MapPin, User, Edit, Trash2,
  ArrowLeft, FileText, Users, CheckCircle, AlertCircle 
} from 'lucide-react';
import { format } from 'date-fns';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';

const MeetingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  
  const [meeting, setMeeting] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  
  // Fetch meeting data
  useEffect(() => {
    const fetchMeeting = async () => {
      if (!id) return;
      
      setIsLoading(true);
      try {
        const meetingDoc = await getDoc(doc(db, "meetings", id));
        
        if (meetingDoc.exists()) {
          const data = meetingDoc.data();
          setMeeting({
            id: meetingDoc.id,
            ...data,
            date: data.date?.toDate() || null,
            endTime: data.endTime?.toDate() || null,
            createdAt: data.createdAt?.toDate() || null,
            updatedAt: data.updatedAt?.toDate() || null
          });
        } else {
          setError("Meeting not found");
        }
      } catch (err) {
        console.error("Error fetching meeting:", err);
        setError("Failed to load meeting details");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchMeeting();
  }, [id]);
  
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await deleteDoc(doc(db, "meetings", id));
      navigate('/meetings');
    } catch (err) {
      console.error("Error deleting meeting:", err);
      setError("Failed to delete meeting");
      setIsLoading(false);
    }
  };
  
  // Format dates
  const formatDateTime = (date) => {
    if (!date) return 'Not specified';
    return format(date, 'MMM d, yyyy • h:mm a');
  };
  
  const formatDuration = () => {
    if (!meeting?.date || !meeting?.endTime) return 'Duration not specified';
    
    const start = new Date(meeting.date);
    const end = new Date(meeting.endTime);
    const durationMs = end - start;
    const minutes = Math.floor(durationMs / (1000 * 60));
    
    if (minutes < 60) {
      return `${minutes} minutes`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} hour${hours > 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} minutes` : ''}`;
    }
  };
  
  const getMeetingStatus = () => {
    if (!meeting?.date) return { label: 'Not Scheduled', color: 'bg-gray-100 text-gray-800' };
    
    const now = new Date();
    const meetingDate = new Date(meeting.date);
    
    if (meetingDate < now) {
      return { label: 'Completed', color: 'bg-gray-100 text-gray-800' };
    }
    
    // Check if meeting is happening today
    const today = new Date();
    const isToday = meetingDate.getDate() === today.getDate() &&
                    meetingDate.getMonth() === today.getMonth() &&
                    meetingDate.getFullYear() === today.getFullYear();
                    
    if (isToday) {
      return { label: 'Today', color: 'bg-green-100 text-green-800' };
    }
    
    // Calculate days until meeting
    const diffTime = Math.abs(meetingDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays <= 3) {
      return { label: 'Upcoming', color: 'bg-yellow-100 text-yellow-800' };
    }
    
    return { label: 'Scheduled', color: 'bg-blue-100 text-blue-800' };
  };
  
  // Check if current user can edit/delete the meeting
  const canEditMeeting = () => {
    if (!meeting || !userProfile) return false;
    
    // Allow editing if user created the meeting or if they're from the same institution
    return meeting.createdBy === userProfile.id || 
           meeting.institutionName === userProfile.institutionName;
  };
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        <p className="ml-2">Loading meeting details...</p>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex">
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
            <p className="text-red-800">{error}</p>
          </div>
        </div>
        <button
          onClick={() => navigate('/meetings')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Meetings
        </button>
      </div>
    );
  }
  
  if (!meeting) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 mb-6">
          <div className="flex">
            <AlertCircle className="h-6 w-6 text-yellow-500 mr-2" />
            <p className="text-yellow-800">Meeting not found</p>
          </div>
        </div>
        <button
          onClick={() => navigate('/meetings')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Meetings
        </button>
      </div>
    );
  }
  
  const status = getMeetingStatus();
  
  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Header with back button and actions */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
        <button
          onClick={() => navigate('/meetings')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Meetings
        </button>
        
        {canEditMeeting() && (
          <div className="mt-4 sm:mt-0 space-x-2">
            <Link
              to={`/meetings/edit/${id}`}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Edit className="h-4 w-4 mr-2" />
              Edit
            </Link>
            
            <button
              onClick={() => setShowDeleteConfirmation(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete
            </button>
          </div>
        )}
      </div>
      
      {/* Meeting Details Card */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {meeting.title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {meeting.type && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-2">
                  {meeting.type}
                </span>
              )}
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${status.color}`}>
                {status.label}
              </span>
            </p>
          </div>
          
          <div className="flex space-x-2">
            <Link
              to={`/meetings/agenda/${id}`}
              className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FileText className="h-4 w-4 mr-2" />
              Agenda
            </Link>
            
            <Link
              to={`/meetings/minutes/${id}`}
              className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FileText className="h-4 w-4 mr-2" />
              Minutes
            </Link>
          </div>
        </div>
        
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                <Calendar className="inline h-4 w-4 mr-1" /> Date and Time
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {formatDateTime(meeting.date)}
                <span className="text-gray-500"> to </span>
                {formatDateTime(meeting.endTime)}
                <div className="mt-1 text-xs text-gray-500">Duration: {formatDuration()}</div>
              </dd>
            </div>
            
            {meeting.location && (
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  <MapPin className="inline h-4 w-4 mr-1" /> Location
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {meeting.location}
                </dd>
              </div>
            )}
            
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                <User className="inline h-4 w-4 mr-1" /> Meeting Secretary
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {meeting.secretary || 'Not assigned'}
              </dd>
            </div>
            
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Description
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {meeting.description || 'No description provided'}
              </dd>
            </div>
            
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                <Users className="inline h-4 w-4 mr-1" /> Participants
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {Array.isArray(meeting.participants) && meeting.participants.length > 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {meeting.participants.map((participant, index) => (
                      <li key={index} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <User className="flex-shrink-0 h-5 w-5 text-gray-400" />
                          <span className="ml-2 flex-1 w-0 truncate">
                            {typeof participant === 'string' ? participant : 
                              (participant.name || 'Unknown Participant')}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No participants have been added</p>
                )}
              </dd>
            </div>
            
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Institution
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {meeting.institutionName || 'Not specified'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      Delete Meeting
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this meeting? This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowDeleteConfirmation(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingDetails;