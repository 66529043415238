// src/pages/plans/PlanPage.js
import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { PlusCircle, FileText } from 'lucide-react';

const PlanPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we're on the main plan list page
  const isMainPage = location.pathname === '/plans' || location.pathname === '/plans/';

  return (
    <div className="space-y-6">
      
              <p className="text-purple-800 text-sm">
                Create and manage your strategic business plans and initiatives.
              </p>
      {/* Nested Routes */}
      <Outlet />
    </div>
  );
};

export default PlanPage;