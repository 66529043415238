// src/components/communication/TimeSelector.jsx
import React, { useRef, useEffect } from 'react';
import { Calendar, Clock } from 'lucide-react';

const TimeSelector = ({ onSelect, onClose, position = 'bottom' }) => {
  const ref = useRef(null);
  
  // Close when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const timeOptions = [
    { value: 'today', label: 'Today', icon: <Calendar className="h-4 w-4" /> },
    { value: 'yesterday', label: 'Yesterday', icon: <Calendar className="h-4 w-4" /> },
    { value: 'this_week', label: 'This Week', icon: <Calendar className="h-4 w-4" /> },
    { value: 'last_week', label: 'Last Week', icon: <Calendar className="h-4 w-4" /> },
    { value: 'this_month', label: 'This Month', icon: <Calendar className="h-4 w-4" /> },
    { value: 'last_month', label: 'Last Month', icon: <Calendar className="h-4 w-4" /> },
    { value: 'this_quarter', label: 'This Quarter', icon: <Calendar className="h-4 w-4" /> },
    { value: 'last_quarter', label: 'Last Quarter', icon: <Calendar className="h-4 w-4" /> },
    { value: 'this_year', label: 'This Year', icon: <Calendar className="h-4 w-4" /> },
    { value: 'last_year', label: 'Last Year', icon: <Calendar className="h-4 w-4" /> },
    { value: 'all_time', label: 'All Time', icon: <Clock className="h-4 w-4" /> },
  ];

  return (
    <div 
      ref={ref}
      className="absolute z-10 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      style={{ 
        left: 0, 
        ...(position === 'top' 
          ? { bottom: '100%', marginBottom: '4px' } 
          : { top: '100%', marginTop: '4px' })
      }}
    >
      <div className="py-1 max-h-60 overflow-y-auto">
        {timeOptions.map((option) => (
          <button
            key={option.value}
            className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
            onClick={() => onSelect(option.value)}
          >
            <span className="mr-2 text-gray-500">{option.icon}</span>
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TimeSelector;