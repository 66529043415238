// src/components/communication/EmailTemplateEditor.js
import React, { useState, useEffect, useRef } from 'react';
import { 
  ArrowLeft, Save, X, Image, Link, List, Bold, Italic, Underline, 
  AlignLeft, AlignCenter, AlignRight, Code, Check, FileText, Eye, Settings,
  Upload, Loader, AlertCircle
} from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage, db } from '../../../firebase/config'; // Import Firebase config
import { 
  collection, doc, setDoc, addDoc, updateDoc, serverTimestamp,
  getDoc 
} from 'firebase/firestore'; // Import Firestore functions
import { useAuth } from '../../../context/AuthContext'; // Import auth context

const EmailTemplateEditor = ({ onSave, onCancel, template = null }) => {
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams(); // If editing, get template ID from URL
  
  const [templateData, setTemplateData] = useState({
    name: '',
    subject: '',
    content: '<p>Enter your email content here...</p>',
    variables: [],
    previewText: '',
    type: 'email',
    status: 'active'
  });
  
  const [activeTab, setActiveTab] = useState('design');
  const [isDirty, setIsDirty] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  const fileInputRef = useRef(null);
  
  // Load template data if editing existing template
  useEffect(() => {
    const loadTemplate = async () => {
      // If we have an ID from URL params, load the template
      if (id) {
        try {
          const templateDoc = await getDoc(doc(db, 'templates', id));
          if (templateDoc.exists()) {
            const data = templateDoc.data();
            setTemplateData({
              name: data.name || '',
              subject: data.subject || '',
              content: data.content || '<p>Enter your email content here...</p>',
              variables: data.variables || [],
              previewText: data.previewText || '',
              type: data.type || 'email',
              status: data.status || 'active'
            });
          } else {
            setError("Template not found");
            setTimeout(() => navigate('/communication/email'), 3000);
          }
        } catch (err) {
          console.error("Error loading template:", err);
          setError("Error loading template. Please try again.");
        }
      } 
      // If we have a template prop (from parent component), use that
      else if (template) {
        setTemplateData({
          name: template.name || '',
          subject: template.subject || '',
          content: template.content || '<p>Enter your email content here...</p>',
          variables: template.variables || [],
          previewText: template.previewText || '',
          type: template.type || 'email',
          status: template.status || 'active'
        });
      }
    };
    
    loadTemplate();
  }, [id, template, navigate]);
  
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTemplateData(prev => ({
      ...prev,
      [name]: value
    }));
    setIsDirty(true);
    
    // Clear any previous error
    if (error) setError(null);
  };
  
  // Handle content changes from editor
  const handleContentChange = (content) => {
    setTemplateData(prev => ({
      ...prev,
      content
    }));
    setIsDirty(true);
    
    // Find and extract variables from content
    // This regex looks for {{variableName}} pattern
    const variableRegex = /\{\{([^}]+)\}\}/g;
    const matches = [...content.matchAll(variableRegex)];
    const extractedVariables = matches.map(match => match[1]);
    
    // Update variables if they have changed
    if (JSON.stringify(extractedVariables) !== JSON.stringify(templateData.variables)) {
      setTemplateData(prev => ({
        ...prev,
        variables: extractedVariables
      }));
    }
  };
  
  // Toggle preview mode
  const togglePreview = () => {
    setShowPreview(!showPreview);
  };
  
  // Save template to Firestore
  const saveTemplate = async () => {
    // Validate template
    if (!templateData.name.trim()) {
      setError('Template name is required');
      return;
    }
    
    if (!templateData.subject.trim()) {
      setError('Email subject is required');
      return;
    }
    
    if (!templateData.content.trim() || templateData.content === '<p>Enter your email content here...</p>') {
      setError('Email content is required');
      return;
    }
    
    // Start saving
    setIsSaving(true);
    setError(null);
    
    try {
      // Prepare template data for firestore
      const templateToSave = {
        ...templateData,
        updatedAt: serverTimestamp(),
        updatedBy: currentUser.uid,
        businessId: userProfile.businessId || null
      };
      
      let templateId;
      
      // Update existing or create new template
      if (id) {
        // Update existing template
        await updateDoc(doc(db, 'templates', id), templateToSave);
        templateId = id;
        setSuccessMessage("Template updated successfully!");
      } else {
        // Create new template
        const newTemplateData = {
          ...templateToSave,
          createdAt: serverTimestamp(),
          createdBy: currentUser.uid,
          lastUsed: null
        };
        
        const docRef = await addDoc(collection(db, 'templates'), newTemplateData);
        templateId = docRef.id;
        setSuccessMessage("Template created successfully!");
      }
      
      // If there's a parent component handler, call it
      if (onSave) {
        onSave({
          ...templateData,
          id: templateId
        });
      }
      
      setIsDirty(false);
      
      // Redirect after short delay to show success message
      setTimeout(() => {
        navigate('/communication/email');
      }, 1500);
      
    } catch (err) {
      console.error("Error saving template:", err);
      setError("Failed to save template. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };
  
  // Handle file upload to Firebase Storage
  const handleFileUpload = async (file) => {
    if (!file) return;
    
    try {
      setIsUploading(true);
      setUploadProgress(0);
      setError(null);
      
      // Create a unique filename
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `email_templates/${Date.now()}_${Math.random().toString(36).substring(2, 15)}.${fileExtension}`;
      
      // Create storage reference
      const storageRef = ref(storage, uniqueFileName);
      
      // Upload file with progress tracking
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      // Return a Promise that resolves with the download URL
      return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Track upload progress
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Upload error:", error);
            setIsUploading(false);
            setError("Failed to upload file: " + error.message);
            reject(error);
          },
          async () => {
            try {
              // Get download URL when upload completes
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              setIsUploading(false);
              resolve(downloadURL);
            } catch (error) {
              console.error("Error getting download URL:", error);
              setIsUploading(false);
              setError("Failed to get file URL: " + error.message);
              reject(error);
            }
          }
        );
      });
    } catch (error) {
      console.error("Error in file upload:", error);
      setIsUploading(false);
      setError("An error occurred during upload: " + error.message);
      throw error;
    }
  };
  
  // Function to trigger file selection dialog
  const triggerFileUpload = (imageOnly = false) => {
    if (fileInputRef.current) {
      fileInputRef.current.accept = imageOnly ? 'image/*' : '*/*';
      fileInputRef.current.click();
    }
  };
  
  // Handler for file input change
  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const downloadURL = await handleFileUpload(file);
        
        // Insert the image or link into the editor
        if (file.type.startsWith('image/')) {
          document.execCommand('insertImage', false, downloadURL);
        } else {
          // For non-image files, insert as a link
          const fileName = file.name;
          document.execCommand('insertHTML', false, 
            `<a href="${downloadURL}" target="_blank">${fileName}</a>`);
        }
        
        // Mark as dirty since content has changed
        setIsDirty(true);
      } catch (error) {
        setError("Failed to upload file: " + error.message);
      }
    }
  };
  
  // Execute editor commands
  const execCommand = (command) => {
    try {
      if (command === 'insertImage') {
        triggerFileUpload(true); // Image files only
      } else if (command === 'createLink') {
        // For links, we have two options: enter URL or upload a file
        const linkType = window.confirm('Do you want to upload a file? Click OK to upload a file or Cancel to enter a URL.');
        if (linkType) {
          triggerFileUpload(false); // Any file type
        } else {
          const url = prompt('Enter URL:', 'https://');
          if (url) {
            document.execCommand('createLink', false, url);
          }
        }
      } else {
        // All other commands use the default behavior
        document.execCommand(command, false, null);
      }
    } catch (error) {
      console.error("Error executing command:", error);
      setError("An error occurred while editing: " + error.message);
    }
  };
  
  // Editor toolbar buttons
  const editorButtons = [
    { icon: Bold, action: 'bold', title: 'Bold' },
    { icon: Italic, action: 'italic', title: 'Italic' },
    { icon: Underline, action: 'underline', title: 'Underline' },
    { icon: AlignLeft, action: 'justifyLeft', title: 'Align Left' },
    { icon: AlignCenter, action: 'justifyCenter', title: 'Align Center' },
    { icon: AlignRight, action: 'justifyRight', title: 'Align Right' },
    { icon: List, action: 'insertUnorderedList', title: 'Bullet List' },
    { icon: Image, action: 'insertImage', title: 'Insert Image' },
    { icon: Link, action: 'createLink', title: 'Insert Link' }
  ];
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      {/* Header with actions */}
      <div className="bg-gray-50 border-b border-gray-200 px-6 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <button
            type="button"
            onClick={onCancel || (() => navigate('/communication/email'))}
            className="text-gray-500 hover:text-gray-700 mr-4"
          >
            <ArrowLeft size={20} />
          </button>
          <h2 className="text-lg font-medium text-gray-900">
            {id ? 'Edit Email Template' : 'Create Email Template'}
          </h2>
        </div>
        
        <div className="flex items-center space-x-2">
          <button
            type="button"
            onClick={togglePreview}
            className={`p-2 rounded-lg ${
              showPreview ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-100 text-gray-600'
            }`}
            title="Preview Template"
          >
            <Eye size={18} />
          </button>
          <button
            type="button"
            onClick={saveTemplate}
            className={`px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center ${
              (isSaving || !isDirty) ? 'opacity-70 cursor-not-allowed' : ''
            }`}
            disabled={isSaving || !isDirty}
          >
            {isSaving ? (
              <>
                <Loader size={18} className="animate-spin mr-2" />
                Saving...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                Save Template
              </>
            )}
          </button>
        </div>
      </div>
      
      {/* Error and success messages */}
      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 text-red-700 flex items-start">
          <AlertCircle size={20} className="mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}
      
      {successMessage && (
        <div className="bg-green-50 border-l-4 border-green-500 p-4 text-green-700 flex items-start">
          <Check size={20} className="mr-2 flex-shrink-0" />
          <p>{successMessage}</p>
        </div>
      )}
      
      {/* Template Information */}
      <div className="p-6 border-b border-gray-200">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Template Name <span className="text-red-500">*</span>
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={templateData.name}
              onChange={handleInputChange}
              placeholder="Enter template name"
              disabled={isSaving}
            />
          </div>
          <div>
            <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
              Email Subject <span className="text-red-500">*</span>
            </label>
            <input
              id="subject"
              name="subject"
              type="text"
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={templateData.subject}
              onChange={handleInputChange}
              placeholder="Enter email subject line"
              disabled={isSaving}
            />
          </div>
        </div>
        
        <div className="mt-4">
          <label htmlFor="previewText" className="block text-sm font-medium text-gray-700 mb-1">
            Preview Text (optional)
          </label>
          <input
            id="previewText"
            name="previewText"
            type="text"
            className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            value={templateData.previewText}
            onChange={handleInputChange}
            placeholder="Brief text that appears in email clients as a preview"
            disabled={isSaving}
          />
          <p className="mt-1 text-xs text-gray-500">
            This text will appear in the recipient's inbox as a preview of the email content.
          </p>
        </div>
      </div>
      
      {/* Editor Tabs */}
      <div className="border-b border-gray-200">
        <nav className="flex px-6 -mb-px">
          <button
            onClick={() => setActiveTab('design')}
            className={`py-4 px-4 text-sm font-medium border-b-2 ${
              activeTab === 'design'
                ? 'border-[#190192] text-[#190192]' 
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            disabled={isSaving}
          >
            Design
          </button>
          <button
            onClick={() => setActiveTab('code')}
            className={`py-4 px-4 text-sm font-medium border-b-2 ${
              activeTab === 'code'
                ? 'border-[#190192] text-[#190192]' 
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            disabled={isSaving}
          >
            HTML
          </button>
          <button
            onClick={() => setActiveTab('settings')}
            className={`py-4 px-4 text-sm font-medium border-b-2 ${
              activeTab === 'settings'
                ? 'border-[#190192] text-[#190192]' 
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            disabled={isSaving}
          >
            Settings
          </button>
        </nav>
      </div>
      
      {/* Hidden file input */}
      <input 
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileInputChange}
        disabled={isSaving || isUploading}
      />
      
      {showPreview ? (
        // Email Preview
        <div className="p-6">
          <div className="bg-gray-50 rounded-lg p-6 border border-gray-200">
            <div className="bg-white rounded-lg shadow-sm p-4 max-w-2xl mx-auto">
              <h3 className="font-medium text-lg mb-2">{templateData.subject}</h3>
              <div 
                className="prose prose-sm max-w-none"
                dangerouslySetInnerHTML={{ __html: templateData.content }}
              />
            </div>
          </div>
        </div>
      ) : (
        // Editor Content Based on Active Tab
        <div className="p-6">
          {activeTab === 'design' && (
            <div>
              {/* Editor Toolbar */}
              <div className="bg-gray-50 border border-gray-200 rounded-t-lg p-2 flex flex-wrap items-center gap-1">
                {editorButtons.map((button, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => execCommand(button.action)}
                    className="p-2 rounded hover:bg-gray-200 text-gray-600"
                    title={button.title}
                    disabled={isSaving || isUploading}
                  >
                    <button.icon size={18} />
                  </button>
                ))}
              </div>
              
              {/* Upload Progress Indicator */}
              {isUploading && (
                <div className="bg-blue-50 text-blue-700 p-2 flex items-center">
                  <Loader size={16} className="animate-spin mr-2" />
                  <span className="text-sm">Uploading file... {Math.round(uploadProgress)}%</span>
                  <div className="ml-2 h-1.5 w-24 bg-blue-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-blue-600 rounded-full"
                      style={{ width: `${uploadProgress}%` }}
                    />
                  </div>
                </div>
              )}
              
              {/* WYSIWYG Editor */}
              <div
                className="border border-t-0 border-gray-200 rounded-b-lg p-4 min-h-[300px] focus:outline-none"
                contentEditable="true"
                dangerouslySetInnerHTML={{ __html: templateData.content }}
                onBlur={(e) => handleContentChange(e.target.innerHTML)}
                style={{ pointerEvents: isSaving || isUploading ? 'none' : 'auto' }}
              />
            </div>
          )}
          
          {activeTab === 'code' && (
            <div>
              <div className="bg-gray-50 border border-gray-200 rounded-t-lg p-2 flex items-center">
                <Code size={18} className="text-gray-500 mr-2" />
                <span className="text-sm font-medium text-gray-700">HTML Code</span>
              </div>
              <textarea
                className="w-full border border-t-0 border-gray-200 rounded-b-lg p-4 h-[300px] font-mono text-sm focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={templateData.content}
                onChange={(e) => handleContentChange(e.target.value)}
                disabled={isSaving || isUploading}
              />
            </div>
          )}
          
          {activeTab === 'settings' && (
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Template Variables</h3>
              <p className="text-sm text-gray-600 mb-4">
                Add variables that will be replaced with actual data when sending the email.
              </p>
              
              <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Available Variables
                    </label>
                    <select 
                      className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                      disabled={isSaving}
                    >
                      <option value="{{name}}">Name</option>
                      <option value="{{email}}">Email</option>
                      <option value="{{company}}">Company</option>
                      <option value="{{date}}">Date</option>
                    </select>
                    <button 
                      type="button"
                      className="mt-2 text-sm text-[#190192] hover:underline"
                      onClick={() => {
                        if (activeTab === 'design') {
                          document.execCommand('insertText', false, '{{name}}');
                        } else if (activeTab === 'code') {
                          setTemplateData(prev => ({
                            ...prev,
                            content: prev.content + '{{name}}'
                          }));
                        }
                      }}
                      disabled={isSaving}
                    >
                      Insert Variable
                    </button>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Detected Variables
                    </label>
                    <div className="bg-white p-3 border border-gray-300 rounded-lg min-h-[100px] text-sm">
                      {templateData.variables.length > 0 ? (
                        <ul className="list-disc pl-5">
                          {templateData.variables.map((variable, index) => (
                            <li key={index}>{variable}</li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-gray-500 italic">No variables detected</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EmailTemplateEditor;