// src/pages/Meetings.js
import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { FileText, Calendar } from 'lucide-react';

const Meetings = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Check if we're on the main records list page
    const isMainPage = location.pathname === '/meetings' || location.pathname === '/meetings/';

  return (
    <div className="space-y-6">
     
        <p className="text-purple-800 text-sm ml-2">
          Manage and organise your meetings efficiently.
        </p>
      
      <Outlet />
    </div>
  );
};

export default Meetings;
