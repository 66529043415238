// src/components/communication/ExternalMessaging.js
import React, { useState, useEffect } from 'react';
import { 
  Mail, 
  MessageSquare, 
  Facebook, 
  Instagram, 
  Linkedin, 
  Send, 
  FileText, 
  Clock, 
  Copy, 
  Edit, 
  Trash2,
  Users, 
  Filter, 
  ChevronDown, 
  Search, 
  ExternalLink, 
  CheckCircle, 
  Globe,
  Plus,
  File,
  PlusCircle,
  X,
  Settings,
  Loader
} from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  limit 
} from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';

// Import the EmailTemplateEditor component
import EmailTemplateEditor from '../communication/email/EmailTemplateEditor';

// Custom Template icon component
const Template = ({ size = 24, className = "" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={size} 
    height={size} 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    className={className}
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="3" y1="9" x2="21" y2="9"></line>
    <line x1="9" y1="21" x2="9" y2="9"></line>
  </svg>
);

const ExternalMessaging = () => {
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();
  
  const [activeTab, setActiveTab] = useState('email');
  const [templates, setTemplates] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [showTemplateEditor, setShowTemplateEditor] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Mock data for fallback
  const mockTemplates = [
    { id: '1', name: 'Welcome Email', type: 'email', subject: 'Welcome to our business!', lastUsed: '2025-03-12', status: 'active' },
    { id: '2', name: 'Follow-up', type: 'email', subject: 'Following up on our conversation', lastUsed: '2025-03-10', status: 'active' },
    { id: '3', name: 'Monthly Newsletter', type: 'email', subject: 'Your Monthly Business Update', lastUsed: '2025-03-01', status: 'active' },
    { id: '4', name: 'Appointment Reminder', type: 'sms', content: 'Reminder: You have an appointment tomorrow at 2 PM', lastUsed: '2025-03-05', status: 'active' },
    { id: '5', name: 'Payment Confirmation', type: 'whatsapp', content: "We've received your payment. Thank you!", lastUsed: '2025-03-08', status: 'active' },
    { id: '6', name: 'New Product Announcement', type: 'social', content: "Exciting news! We've just launched our new product line.", lastUsed: '2025-02-28', status: 'active' }
  ];
  
  const mockCampaigns = [
    { id: '1', name: 'March Newsletter', type: 'email', recipients: 245, sent: 230, opened: 125, clicked: 68, status: 'completed', date: '2025-03-05' },
    { id: '2', name: 'Product Promotion', type: 'email', recipients: 350, sent: 350, opened: 180, clicked: 79, status: 'completed', date: '2025-02-20' },
    { id: '3', name: 'Customer Survey', type: 'email', recipients: 420, sent: 0, opened: 0, clicked: 0, status: 'scheduled', date: '2025-04-15' },
    { id: '4', name: 'Service Reminder', type: 'sms', recipients: 156, sent: 156, opened: null, clicked: null, status: 'completed', date: '2025-03-12' },
    { id: '5', name: 'Holiday Greetings', type: 'email', recipients: 600, sent: 0, opened: 0, clicked: 0, status: 'draft', date: null }
  ];
  
  // Fetch templates from Firestore
  useEffect(() => {
    const fetchTemplates = async () => {
      if (!currentUser || !userProfile?.businessId) {
        // If not logged in or no business ID, use mock data
        setTemplates(mockTemplates);
        setCampaigns(mockCampaigns);
        setIsLoading(false);
        return;
      }
      
      try {
        setIsLoading(true);
        setError(null);
        
        // Fetch templates
        const templatesRef = collection(db, 'templates');
        const templatesQuery = query(
          templatesRef,
          where('businessId', '==', userProfile.businessId),
          where('type', '==', 'email'),
          orderBy('updatedAt', 'desc')
        );
        
        const templatesSnapshot = await getDocs(templatesQuery);
        
        if (!templatesSnapshot.empty) {
          const templatesList = [];
          templatesSnapshot.forEach(doc => {
            const data = doc.data();
            templatesList.push({
              id: doc.id,
              ...data,
              lastUsed: data.lastUsed?.toDate?.().toISOString().split('T')[0] || null
            });
          });
          setTemplates(templatesList);
        } else {
          // If no templates found in database, use mock data
          setTemplates(mockTemplates);
        }
        
        // Here you would fetch campaigns from Firestore
        // For now, we'll just use mock data
        setCampaigns(mockCampaigns);
        
      } catch (err) {
        console.error("Error fetching templates:", err);
        setError("Failed to load templates. Using sample data instead.");
        
        // Use mock data as fallback
        setTemplates(mockTemplates);
        setCampaigns(mockCampaigns);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTemplates();
  }, [currentUser, userProfile]);
  
  // Filter templates based on search term
  const filteredTemplates = templates.filter(template => 
    template.type === 'email' && 
    (searchTerm === '' || 
     template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
     template.subject.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  
  // Handle edit template
  const handleEditTemplate = (template) => {
    navigate(`/communication/email/template/edit/${template.id}`);
  };
  
  // Handle create new template
  const handleCreateTemplate = () => {
    navigate('/communication/email/template/new');
  };
  
  // Handle delete template
  const handleDeleteTemplate = (templateId) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      // In a real implementation, this would delete from Firestore
      setTemplates(prev => prev.filter(t => t.id !== templateId));
    }
  };
  
  // Handle duplicate template
  const handleDuplicateTemplate = (template) => {
    const newTemplate = {
      ...template,
      id: Date.now().toString(),
      name: `${template.name} (Copy)`,
      lastUsed: new Date().toISOString().split('T')[0]
    };
    
    setTemplates(prev => [...prev, newTemplate]);
  };

  // Campaign action handlers
  const handleEditCampaign = (campaign) => {
    // In a real app, navigate to campaign editor
    console.log('Edit campaign', campaign);
  };

  const handleDeleteCampaign = (campaignId) => {
    if (window.confirm('Are you sure you want to delete this campaign?')) {
      setCampaigns(prev => prev.filter(c => c.id !== campaignId));
    }
  };

  const handleDuplicateCampaign = (campaign) => {
    const newCampaign = {
      ...campaign,
      id: Date.now().toString(),
      name: `${campaign.name} (Copy)`,
      date: new Date().toISOString().split('T')[0]
    };
    setCampaigns(prev => [...prev, newCampaign]);
  };

  // Functions to render different tabs
  const renderEmailTab = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-64">
          <Loader className="animate-spin h-8 w-8 text-[#190192]" />
        </div>
      );
    }
    
    return (
      
      <div className="space-y-6">
        {error && (
          <div className="bg-amber-50 border-l-4 border-amber-500 p-4 text-amber-700 mb-4">
            <p>{error}</p>
          </div>
        )}

        {/* Add a dedicated settings section at the top */}
          <div className="bg-white rounded-xl shadow-md p-4 mb-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Mail className="h-5 w-5 text-[#190192] mr-2" />
                <h3 className="text-lg font-medium text-gray-900">Email Communication</h3>
              </div>
              
              <Link
                to={`/communication/email/settings/${userProfile?.businessId ? userProfile.businessId : 'profile/new'}`}

                className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 flex items-center"
              >
                <Settings size={16} className="mr-2" />
                Email Settings
              </Link>
            </div>
            {!userProfile?.businessId && (
              <p className="mt-2 text-amber-600 text-sm">
                Please configure your business profile to enable email functionality.
              </p>
            )}
          </div>
        
        {/* Templates Section */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Email Templates</h3>
            <button 
              className="px-4 py-2 bg-[#190192] text-white rounded-lg flex items-center hover:bg-[#2C0DB5]"
              onClick={handleCreateTemplate}
            >
              <PlusCircle size={16} className="mr-2" />
              Create Template
            </button>
          </div>
          
          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            <div className="flex items-center justify-between p-4 border-b">
              <div className="relative w-64">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                <input
                  type="text"
                  className="pl-10 pr-4 py-2 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
                  placeholder="Search templates..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <button className="px-3 py-2 rounded-lg border border-gray-200 text-gray-600 flex items-center hover:bg-gray-50">
                    <Filter size={16} className="mr-2" />
                    Filter
                    <ChevronDown size={16} className="ml-2" />
                  </button>
                </div>
              </div>
            </div>
            
            {filteredTemplates.length === 0 ? (
              <div className="py-8 text-center">
                <Template size={48} className="mx-auto text-gray-300 mb-3" />
                <h3 className="text-lg font-medium text-gray-900 mb-1">No templates found</h3>
                <p className="text-gray-500 mb-4">
                  {searchTerm ? 'No templates match your search criteria' : 'You haven\'t created any email templates yet'}
                </p>
                <button 
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg inline-flex items-center hover:bg-[#2C0DB5]"
                  onClick={handleCreateTemplate}
                >
                  <PlusCircle size={16} className="mr-2" />
                  Create Your First Template
                </button>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Template Name</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subject</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Used</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredTemplates.map((template) => (
                      <tr key={template.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <Template size={18} className="text-gray-400 mr-2" />
                            <span className="text-sm font-medium text-gray-900">{template.name}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{template.subject}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{template.lastUsed}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button 
                            className="text-[#190192] hover:text-[#2C0DB5] mr-3"
                            onClick={() => handleEditTemplate(template)}
                          >
                            <Edit size={16} />
                          </button>
                          <button 
                            className="text-gray-400 hover:text-red-600 mr-3"
                            onClick={() => handleDeleteTemplate(template.id)}
                          >
                            <Trash2 size={16} />
                          </button>
                          <button 
                            className="text-gray-400 hover:text-gray-600"
                            onClick={() => handleDuplicateTemplate(template)}
                          >
                            <Copy size={16} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        
        {/* Campaigns Section */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Email Campaigns</h3>
            <Link
              to="/communication/email/send"
              className="px-4 py-2 bg-[#190192] text-white rounded-lg flex items-center hover:bg-[#2C0DB5]"
            >
              <PlusCircle size={16} className="mr-2" />
              Create Campaign
            </Link>
          </div>
          
          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign Name</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Recipients</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Performance</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {campaigns
                    .filter(campaign => campaign.type === 'email')
                    .map((campaign) => (
                    <tr key={campaign.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm font-medium text-gray-900">{campaign.name}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {campaign.recipients}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          campaign.status === 'completed'
                            ? 'bg-green-100 text-green-800'
                            : campaign.status === 'scheduled'
                            ? 'bg-blue-100 text-blue-800'
                            : 'bg-gray-100 text-gray-800'
                        }`}>
                          {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {campaign.date || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-500">Sent: {campaign.sent}</span>
                          <span className="text-sm text-gray-500">Opened: {campaign.opened}</span>
                          <span className="text-sm text-gray-500">Clicked: {campaign.clicked}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button 
                          className="text-[#190192] hover:text-[#2C0DB5] mr-3"
                          onClick={() => handleEditCampaign(campaign)}
                        >
                          <Edit size={16} />
                        </button>
                        <button 
                          className="text-gray-400 hover:text-red-600 mr-3"
                          onClick={() => handleDeleteCampaign(campaign.id)}
                        >
                          <Trash2 size={16} />
                        </button>
                        <button 
                          className="text-gray-400 hover:text-gray-600"
                          onClick={() => handleDuplicateCampaign(campaign)}
                        >
                          <Copy size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6">
      {/* Tabs navigation can be extended as needed */}
      <div className="mb-6">
        <button 
          className={`px-4 py-2 mr-4 ${activeTab === 'email' ? 'bg-[#190192] text-white' : 'bg-gray-200 text-gray-800'} rounded-lg`}
          onClick={() => setActiveTab('email')}
        >
          Email
        </button>
        {/* Future tabs (e.g. SMS, WhatsApp, Social) */}
      </div>
      {activeTab === 'email' && renderEmailTab()}
    </div>
  );
};

export default ExternalMessaging;