// src/pages/finance/FinancePage.jsx
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { PlusCircle, DollarSign, List, Home, BarChart2, CreditCard } from 'lucide-react';

const FinancePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if we're on the main finance list page
  const isMainPage = location.pathname === '/finance' || location.pathname === '/finance/';
  const isDashboardPage = location.pathname === '/finance/dashboard';
  
  // Determine active tab
  const getActiveTab = () => {
    const path = location.pathname;
    if (path.includes('/finance/dashboard')) return 'dashboard';
    if (path === '/finance' || path === '/finance/' || path.includes('/finance/transaction')) return 'transactions';
    if (path.includes('/finance/accounts')) return 'accounts';
    if (path.includes('/finance/reports')) return 'reports';
    return 'transactions'; // Default to transactions
  };
  
  const activeTab = getActiveTab();
  
  // Scroll to active tab on mount and when tab changes
  useEffect(() => {
    const activeTabElement = document.querySelector('.active-tab');
    if (activeTabElement) {
      activeTabElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    }
  }, [activeTab]);

  return (
    <div className="space-y-6">
      {/* Header */}
      
              <p className="text-purple-800 text-sm mb-2">
                Track income, expenses, invoices, and budgets in one place
              </p>

            
        {/* Tabs - Fixed structure for proper scrolling */}
        <div className="overflow-x-auto -mx-4 border-b border-gray-200">
          <nav className="flex min-w-max px-6">
            <Link
              to="/finance/dashboard"
              className={`py-4 px-4 text-sm font-medium border-b-2 -mb-px whitespace-nowrap ${
                activeTab === 'dashboard'
                  ? 'border-[#190192] text-[#190192] active-tab' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <span className="flex items-center">
                <Home size={16} className={`mr-2 ${activeTab === 'dashboard' ? 'text-[#190192]' : 'text-gray-400'}`} />
                Dashboard
              </span>
            </Link>
            
            <Link
              to="/finance"
              className={`py-4 px-4 text-sm font-medium border-b-2 -mb-px whitespace-nowrap ${
                activeTab === 'transactions'
                  ? 'border-[#190192] text-[#190192] active-tab' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <span className="flex items-center">
                <List size={16} className={`mr-2 ${activeTab === 'transactions' ? 'text-[#190192]' : 'text-gray-400'}`} />
                Transactions
              </span>
            </Link>
            
            <Link
              to="/finance/accounts"
              className={`py-4 px-4 text-sm font-medium border-b-2 -mb-px whitespace-nowrap ${
                activeTab === 'accounts'
                  ? 'border-[#190192] text-[#190192] active-tab' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <span className="flex items-center">
                <CreditCard size={16} className={`mr-2 ${activeTab === 'accounts' ? 'text-[#190192]' : 'text-gray-400'}`} />
                Accounts
              </span>
            </Link>
            
            <Link
              to="/finance/reports"
              className={`py-4 px-4 text-sm font-medium border-b-2 -mb-px whitespace-nowrap ${
                activeTab === 'reports'
                  ? 'border-[#190192] text-[#190192] active-tab' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <span className="flex items-center">
                <BarChart2 size={16} className={`mr-2 ${activeTab === 'reports' ? 'text-[#190192]' : 'text-gray-400'}`} />
                Reports
              </span>
            </Link>
          </nav>
       
      </div>

      {/* Nested Routes */}
      <Outlet />
    </div>
  );
};

export default FinancePage;