// src/components/meetings/MinutesCapture.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Save, ArrowLeft, FileText, Calendar, Clock, Users, 
  Check, X, Edit, ChevronDown, ChevronUp, AlertCircle,
  Plus, Link as LinkIcon
} from 'lucide-react';
import { format } from 'date-fns';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  collection,
  addDoc,
  serverTimestamp 
} from 'firebase/firestore';

const MinutesCapture = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  
  const [meeting, setMeeting] = useState(null);
  const [agendaItems, setAgendaItems] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [absentees, setAbsentees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [expandedItems, setExpandedItems] = useState({});
  const [generalNotes, setGeneralNotes] = useState('');
  
  // Fetch meeting data
  useEffect(() => {
    const fetchMeeting = async () => {
      if (!id) return;
      
      setIsLoading(true);
      try {
        const meetingDoc = await getDoc(doc(db, "meetings", id));
        
        if (meetingDoc.exists()) {
          const data = meetingDoc.data();
          const meetingData = {
            id: meetingDoc.id,
            ...data,
            date: data.date?.toDate() || null,
            endTime: data.endTime?.toDate() || null,
            createdAt: data.createdAt?.toDate() || null,
            updatedAt: data.updatedAt?.toDate() || null
          };
          
          setMeeting(meetingData);
          
          // Initialize agenda items with discussions, decisions, and tasks fields
          if (Array.isArray(meetingData.agendaItems) && meetingData.agendaItems.length > 0) {
            const initializedItems = meetingData.agendaItems.map(item => ({
              ...item,
              discussion: item.discussion || '',
              decision: item.decision || '',
              tasks: item.tasks || []
            }));
            setAgendaItems(initializedItems);
            
            // Set all agenda items to expanded by default
            const expanded = {};
            initializedItems.forEach(item => {
              expanded[item.id] = true;
            });
            setExpandedItems(expanded);
          }
          
          // Initialize attendees
          if (Array.isArray(meetingData.attendees) && meetingData.attendees.length > 0) {
            setAttendees(meetingData.attendees);
          } else if (Array.isArray(meetingData.participants) && meetingData.participants.length > 0) {
            // Initialize from participants if no attendees set
            const attendeesList = meetingData.participants.map(participant => {
              if (typeof participant === 'string') {
                return participant;
              } else if (participant.name) {
                return participant.name;
              }
              return null;
            }).filter(Boolean);
            
            setAttendees(attendeesList);
          }
          
          // Initialize absentees
          if (Array.isArray(meetingData.absentees)) {
            setAbsentees(meetingData.absentees);
          }
          
          // Initialize general notes
          if (meetingData.generalNotes) {
            setGeneralNotes(meetingData.generalNotes);
          }
        } else {
          setError("Meeting not found");
        }
      } catch (err) {
        console.error("Error fetching meeting:", err);
        setError("Failed to load meeting data");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchMeeting();
  }, [id]);
  
  // Toggle expanded state for an agenda item
  const toggleItemExpanded = (itemId) => {
    setExpandedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };
  
  // Update agenda item discussion
  const updateItemDiscussion = (itemId, value) => {
    setAgendaItems(prev => 
      prev.map(item => 
        item.id === itemId ? { ...item, discussion: value } : item
      )
    );
  };
  
  // Update agenda item decision
  const updateItemDecision = (itemId, value) => {
    setAgendaItems(prev => 
      prev.map(item => 
        item.id === itemId ? { ...item, decision: value } : item
      )
    );
  };
  
  // Add a task to an agenda item
  const addTaskToItem = (itemId, task) => {
    if (!task.description) return;
    
    setAgendaItems(prev => 
      prev.map(item => {
        if (item.id === itemId) {
          return {
            ...item,
            tasks: [
              ...item.tasks,
              {
                id: `task_${Date.now()}`,
                description: task.description,
                assignee: task.assignee,
                dueDate: task.dueDate,
                status: 'pending'
              }
            ]
          };
        }
        return item;
      })
    );
  };
  
  // Remove a task from an agenda item
  const removeTask = (itemId, taskId) => {
    setAgendaItems(prev => 
      prev.map(item => {
        if (item.id === itemId) {
          return {
            ...item,
            tasks: item.tasks.filter(task => task.id !== taskId)
          };
        }
        return item;
      })
    );
  };
  
  // Update attendee status
  const toggleAttendeeStatus = (name) => {
    // Check if person is in attendees
    if (attendees.includes(name)) {
      // Move from attendees to absentees
      setAttendees(prev => prev.filter(p => p !== name));
      setAbsentees(prev => [...prev, name]);
    } else if (absentees.includes(name)) {
      // Move from absentees to attendees
      setAbsentees(prev => prev.filter(p => p !== name));
      setAttendees(prev => [...prev, name]);
    }
  };
  
  // Create a task in the tasks collection (to link with the tasks feature)
  const createLinkedTask = async (task, agendaItem) => {
    if (!task.description || !meeting) return null;
    
    try {
      const taskData = {
        description: task.description,
        assignee: task.assignee,
        dueDate: task.dueDate ? new Date(task.dueDate) : null,
        status: 'pending',
        priority: 'medium',
        createdBy: userProfile?.id,
        institutionId: meeting.institutionId,
        institutionName: meeting.institutionName,
        createdAt: serverTimestamp(),
        linkedFrom: {
          type: 'meeting',
          id: meeting.id,
          name: meeting.title,
          agendaItemId: agendaItem.id,
          agendaItemTitle: agendaItem.title
        }
      };
      
      const taskRef = await addDoc(collection(db, 'tasks'), taskData);
      return taskRef.id;
    } catch (err) {
      console.error("Error creating linked task:", err);
      setError("Failed to create linked task: " + err.message);
      return null;
    }
  };
  
  // Save all minutes data
  const handleSave = async () => {
    if (!meeting) {
      setError('Meeting data not available');
      return;
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      // Create linked tasks for each task in agenda items
      for (const item of agendaItems) {
        if (Array.isArray(item.tasks)) {
          for (let i = 0; i < item.tasks.length; i++) {
            const task = item.tasks[i];
            if (!task.linkedTaskId && task.description) {
              const taskId = await createLinkedTask(task, item);
              if (taskId) {
                // Update the task with the linked task ID
                item.tasks[i] = {
                  ...task,
                  linkedTaskId: taskId
                };
              }
            }
          }
        }
      }
      
      // Update meeting with minutes data
      await updateDoc(doc(db, "meetings", id), {
        agendaItems: agendaItems,
        attendees: attendees,
        absentees: absentees,
        generalNotes: generalNotes,
        minutesLastUpdatedAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        minutesStatus: 'completed'
      });
      
      setSuccess('Minutes saved successfully');
      
      // Navigate back after a short delay
      setTimeout(() => {
        navigate(`/meetings/details/${id}`);
      }, 1500);
    } catch (err) {
      console.error('Error saving minutes:', err);
      setError('Failed to save minutes: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Format date
  const formatDate = (date) => {
    if (!date) return '';
    return format(new Date(date), 'MMMM d, yyyy');
  };
  
  // Format time
  const formatTime = (date) => {
    if (!date) return '';
    return format(new Date(date), 'h:mm a');
  };
  
  // Get all available participants for assignee selection
  const getAvailableParticipants = () => {
    if (!meeting) return [];
    
    const participants = [];
    
    // Add meeting chairperson if available
    if (meeting.chairperson) {
      participants.push(meeting.chairperson);
    }
    
    // Add meeting secretary if available
    if (meeting.secretary) {
      participants.push(meeting.secretary);
    }
    
    // Add meeting participants
    if (Array.isArray(meeting.participants)) {
      meeting.participants.forEach(participant => {
        const name = typeof participant === 'string' ? participant : participant.name;
        if (name && !participants.includes(name)) {
          participants.push(name);
        }
      });
    }
    
    return participants;
  };
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        <p className="ml-2">Loading meeting minutes...</p>
      </div>
    );
  }
  
  if (error && !meeting) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-800">{error}</p>
          </div>
        </div>
        <button
          onClick={() => navigate('/meetings')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Meetings
        </button>
      </div>
    );
  }
  
  if (!meeting) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 mb-6">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-yellow-500 mr-2" />
            <p className="text-yellow-800">Meeting not found</p>
          </div>
        </div>
        <button
          onClick={() => navigate('/meetings')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Meetings
        </button>
      </div>
    );
  }
  
  return (
    <div className="max-w-5xl mx-auto p-6">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">Meeting Minutes</h1>
          <p className="text-sm text-gray-500">
            {meeting.title} - {formatDate(meeting.date)}
          </p>
        </div>
        
        <div className="mt-4 sm:mt-0 space-x-2">
          <button
            onClick={() => navigate(`/meetings/details/${id}`)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back
          </button>
          
          <button
            onClick={handleSave}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            <Save className="h-4 w-4 mr-2" />
            {isLoading ? 'Saving...' : 'Save Minutes'}
          </button>
        </div>
      </div>
      
      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-800">{error}</p>
          </div>
        </div>
      )}
      
      {success && (
        <div className="mb-6 bg-green-50 border-l-4 border-green-500 p-4">
          <div className="flex">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <p className="text-green-800">{success}</p>
          </div>
        </div>
      )}
      
      {/* Meeting Summary Card */}
      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <div className="flex items-center mb-4">
              <Calendar className="h-5 w-5 text-gray-500 mr-2" />
              <div>
                <h3 className="text-sm font-medium text-gray-500">Date</h3>
                <p className="text-sm text-gray-900">{formatDate(meeting.date)}</p>
              </div>
            </div>
            
            <div className="flex items-center">
              <Clock className="h-5 w-5 text-gray-500 mr-2" />
              <div>
                <h3 className="text-sm font-medium text-gray-500">Time</h3>
                <p className="text-sm text-gray-900">
                  {formatTime(meeting.date)} - {formatTime(meeting.endTime)}
                </p>
              </div>
            </div>
          </div>
          
          <div>
            <div className="flex items-center mb-4">
              <FileText className="h-5 w-5 text-gray-500 mr-2" />
              <div>
                <h3 className="text-sm font-medium text-gray-500">Type</h3>
                <p className="text-sm text-gray-900">{meeting.type || 'Not specified'}</p>
              </div>
            </div>
            
            <div className="flex items-center">
              <Users className="h-5 w-5 text-gray-500 mr-2" />
              <div>
                <h3 className="text-sm font-medium text-gray-500">Secretary</h3>
                <p className="text-sm text-gray-900">{meeting.secretary || 'Not assigned'}</p>
              </div>
            </div>
          </div>
          
          <div>
            <div className="flex items-start">
              <Users className="h-5 w-5 text-gray-500 mr-2 mt-0.5" />
              <div>
                <h3 className="text-sm font-medium text-gray-500">Attendance</h3>
                <p className="text-sm text-gray-900">
                  {attendees.length} present, {absentees.length} absent
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Attendance Section */}
      <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Attendance</h2>
        </div>
        
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Present */}
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-3">Present</h3>
              <ul className="divide-y divide-gray-200">
                {meeting.participants && meeting.participants.map((participant, index) => {
                  const name = typeof participant === 'string' ? participant : participant.name;
                  if (!name) return null;
                  
                  const isPresent = attendees.includes(name);
                  
                  return (
                    <li key={index} className="py-2 flex items-center justify-between">
                      <span className="text-sm text-gray-800">{name}</span>
                      <button
                        className={`px-2 py-1 rounded text-xs font-medium ${
                          isPresent 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-gray-100 text-gray-800 hover:bg-green-50 hover:text-green-700'
                        }`}
                        onClick={() => toggleAttendeeStatus(name)}
                      >
                        {isPresent ? 'Present' : 'Mark Present'}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            
            {/* Absent */}
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-3">Absent</h3>
              <ul className="divide-y divide-gray-200">
                {meeting.participants && meeting.participants.map((participant, index) => {
                  const name = typeof participant === 'string' ? participant : participant.name;
                  if (!name) return null;
                  
                  const isAbsent = absentees.includes(name);
                  
                  return (
                    <li key={index} className="py-2 flex items-center justify-between">
                      <span className="text-sm text-gray-800">{name}</span>
                      <button
                        className={`px-2 py-1 rounded text-xs font-medium ${
                          isAbsent 
                            ? 'bg-red-100 text-red-800' 
                            : 'bg-gray-100 text-gray-800 hover:bg-red-50 hover:text-red-700'
                        }`}
                        onClick={() => toggleAttendeeStatus(name)}
                      >
                        {isAbsent ? 'Absent' : 'Mark Absent'}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      {/* General Notes */}
      <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">General Notes</h2>
        </div>
        
        <div className="p-6">
          <textarea
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            rows={4}
            placeholder="Enter general notes about the meeting..."
            value={generalNotes}
            onChange={(e) => setGeneralNotes(e.target.value)}
          ></textarea>
        </div>
      </div>
      
      {/* Agenda Items with Minutes */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Agenda Items</h2>
        </div>
        
        {agendaItems.length === 0 ? (
          <div className="p-6 text-center">
            <FileText className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No agenda items</h3>
            <p className="mt-1 text-sm text-gray-500">Add agenda items first before capturing minutes.</p>
            <button
              onClick={() => navigate(`/meetings/agenda/${id}`)}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Add Agenda Items
            </button>
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            {agendaItems.map((item, index) => (
              <div key={item.id} className="p-6">
                <div className="flex items-center justify-between cursor-pointer" onClick={() => toggleItemExpanded(item.id)}>
                  <div className="flex items-center">
                    <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium mr-3">
                      {index + 1}
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">{item.title}</h3>
                      <div className="flex items-center text-sm text-gray-500">
                        <Clock className="h-4 w-4 mr-1" />
                        <span>{item.duration} minutes</span>
                        {item.presenter && (
                          <>
                            <span className="mx-1">•</span>
                            <Users className="h-4 w-4 mr-1" />
                            <span>{item.presenter}</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <button className="text-gray-400 hover:text-gray-500">
                    {expandedItems[item.id] ? (
                      <ChevronUp className="h-5 w-5" />
                    ) : (
                      <ChevronDown className="h-5 w-5" />
                    )}
                  </button>
                </div>
                
                {expandedItems[item.id] && (
                  <div className="mt-4 pl-11">
                    {/* Discussion */}
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Discussion
                      </label>
                      <textarea
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        rows={4}
                        placeholder="Enter discussion points..."
                        value={item.discussion || ''}
                        onChange={(e) => updateItemDiscussion(item.id, e.target.value)}
                      ></textarea>
                    </div>
                    
                    {/* Decision */}
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Decision
                      </label>
                      <textarea
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        rows={2}
                        placeholder="Enter decision made..."
                        value={item.decision || ''}
                        onChange={(e) => updateItemDecision(item.id, e.target.value)}
                      ></textarea>
                    </div>
                    
                    {/* Tasks */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Tasks
                      </label>
                      
                      {/* Existing Tasks */}
                      {Array.isArray(item.tasks) && item.tasks.length > 0 ? (
                        <ul className="mb-4 divide-y divide-gray-200 border border-gray-200 rounded-md">
                          {item.tasks.map((task, taskIndex) => (
                            <li key={task.id || taskIndex} className="p-3 flex items-start justify-between">
                              <div className="flex-1">
                                <p className="text-sm font-medium text-gray-900">{task.description}</p>
                                <div className="mt-1 flex items-center text-xs text-gray-500">
                                  {task.assignee && (
                                    <span className="mr-3">Assignee: {task.assignee}</span>
                                  )}
                                  {task.dueDate && (
                                    <span>Due: {format(new Date(task.dueDate), 'MMM d, yyyy')}</span>
                                  )}
                                  {task.linkedTaskId && (
                                    <span className="ml-3 flex items-center text-blue-600">
                                      <LinkIcon className="h-3 w-3 mr-1" />
                                      Linked to task
                                    </span>
                                  )}
                                </div>
                              </div>
                              <button
                                className="ml-2 text-gray-400 hover:text-red-500"
                                onClick={() => removeTask(item.id, task.id)}
                              >
                                <X className="h-4 w-4" />
                              </button>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-sm text-gray-500 mb-4">No tasks added yet</p>
                      )}
                      
                      {/* Add New Task */}
                      <div className="border border-gray-200 rounded-md p-3">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">Add New Task</h4>
                        <div className="space-y-3">
                          <input
                            type="text"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Task description"
                            value={item.newTaskDescription || ''}
                            onChange={(e) => setAgendaItems(prev => prev.map(i => 
                              i.id === item.id ? { ...i, newTaskDescription: e.target.value } : i
                            ))}
                          />
                          
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <select
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              value={item.newTaskAssignee || ''}
                              onChange={(e) => setAgendaItems(prev => prev.map(i => 
                                i.id === item.id ? { ...i, newTaskAssignee: e.target.value } : i
                              ))}
                            >
                              <option value="">Select assignee</option>
                              {getAvailableParticipants().map((name, idx) => (
                                <option key={idx} value={name}>{name}</option>
                              ))}
                            </select>
                            
                            <input
                              type="date"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              value={item.newTaskDueDate || ''}
                              onChange={(e) => setAgendaItems(prev => prev.map(i => 
                                i.id === item.id ? { ...i, newTaskDueDate: e.target.value } : i
                              ))}
                            />
                          </div>
                          
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              onClick={() => {
                                if (!item.newTaskDescription) return;
                                addTaskToItem(item.id, {
                                  description: item.newTaskDescription,
                                  assignee: item.newTaskAssignee || '',
                                  dueDate: item.newTaskDueDate || ''
                                });
                                // Clear the form fields after adding
                                setAgendaItems(prev => prev.map(i => 
                                  i.id === item.id ? { 
                                    ...i, 
                                    newTaskDescription: '',
                                    newTaskAssignee: '',
                                    newTaskDueDate: ''
                                  } : i
                                ));
                              }}
                            >
                              <Plus className="h-4 w-4 mr-1" />
                              Add Task
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      
      {/* Footer with save button */}
      <div className="mt-6 flex justify-end">
        <button
          onClick={() => navigate(`/meetings/details/${id}`)}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-3"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          disabled={isLoading}
        >
          <Save className="h-4 w-4 mr-2" />
          {isLoading ? 'Saving...' : 'Save Minutes'}
        </button>
      </div>
    </div>
  );
};

export default MinutesCapture;