// src/pages/business/BusinessPage.js
import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { PlusCircle } from 'lucide-react';

const BusinessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we're on the main business list page
  const isMainPage = location.pathname === '/business' || location.pathname === '/business/';

  return (
    <div className="space-y-6">
      {/* Header */}
              <p className="text-purple-800 text-sm ml-2">
                Manage your business entities, subsidiaries, branches, and departments.
              </p>

      {/* Nested Routes */}
      <Outlet />
    </div>
  );
};

export default BusinessPage;
