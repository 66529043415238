// src/components/communication/EmailAnalytics.js
import React, { useState, useEffect } from 'react';
import { 
  BarChart2, Calendar, Download, Filter, Mail, 
  UserCheck, MousePointer, Loader, AlertCircle, Search,
  RefreshCw, ChevronDown, ChevronUp, Eye, Clock
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase/config';
import { 
  collection, query, where, orderBy, getDocs, 
  startAfter, limit, Timestamp
} from 'firebase/firestore';

const EmailAnalytics = () => {
  const { currentUser, userProfile } = useAuth();
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emailLogs, setEmailLogs] = useState([]);
  const [businessProfiles, setBusinessProfiles] = useState([]);
  const [stats, setStats] = useState({
    totalSent: 0,
    totalOpened: 0,
    totalClicked: 0,
    openRate: 0,
    clickRate: 0
  });
  
  // Filters
  const [filters, setFilters] = useState({
    businessId: '',
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    searchTerm: ''
  });
  
  // Pagination
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  
  // Load business profiles
  useEffect(() => {
    const fetchBusinessProfiles = async () => {
      if (!currentUser) return;
      
      try {
        // Fetch business profiles
        const businessesRef = collection(db, 'businesses');
        const businessQuery = query(businessesRef, where('createdBy', '==', currentUser.uid));
        const businessSnapshot = await getDocs(businessQuery);
        
        const businesses = [];
        businessSnapshot.forEach(doc => {
          businesses.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setBusinessProfiles(businesses);
        
        // If user has businesses, auto-select the first one
        if (businesses.length > 0 && !filters.businessId) {
          setFilters(prev => ({
            ...prev,
            businessId: businesses[0].id
          }));
        }
      } catch (err) {
        console.error("Error fetching business profiles:", err);
        setError("Failed to load business profiles. Please try again.");
      }
    };
    
    fetchBusinessProfiles();
  }, [currentUser]);
  
  // Load email logs based on filters
  useEffect(() => {
    const fetchEmailLogs = async () => {
      if (!currentUser || !filters.businessId) {
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        setError(null);
        
        // Build query constraints
        const constraints = [
          where('businessId', '==', filters.businessId),
          orderBy('sentAt', 'desc')
        ];
        
        // Add date range filters
        if (filters.startDate) {
          constraints.push(where('sentAt', '>=', Timestamp.fromDate(new Date(filters.startDate))));
        }
        
        if (filters.endDate) {
          // Add 1 day to include the entire end date
          const endDate = new Date(filters.endDate);
          endDate.setDate(endDate.getDate() + 1);
          constraints.push(where('sentAt', '<', Timestamp.fromDate(endDate)));
        }
        
        // Create and execute query
        const emailsRef = collection(db, 'emailLogs');
        const emailsQuery = query(emailsRef, ...constraints, limit(20));
        
        const querySnapshot = await getDocs(emailsQuery);
        const logs = [];
        
        querySnapshot.forEach(doc => {
          logs.push({
            id: doc.id,
            ...doc.data(),
            sentAt: doc.data().sentAt?.toDate() || null
          });
        });
        
        setEmailLogs(logs);
        
        // Check if there are more results
        setHasMore(!querySnapshot.empty);
        
        // Save last visible document for pagination
        if (!querySnapshot.empty) {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        } else {
          setLastVisible(null);
        }
        
        // Calculate stats
        const totalSent = logs.length;
        const totalOpened = logs.filter(log => log.opened).length;
        const totalClicked = logs.filter(log => log.clicked).length;
        const openRate = totalSent > 0 ? (totalOpened / totalSent) * 100 : 0;
        const clickRate = totalOpened > 0 ? (totalClicked / totalOpened) * 100 : 0;
        
        setStats({
          totalSent,
          totalOpened,
          totalClicked,
          openRate,
          clickRate
        });
      } catch (err) {
        console.error("Error fetching email logs:", err);
        setError("Failed to load email analytics. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    
    fetchEmailLogs();
  }, [currentUser, filters.businessId, filters.startDate, filters.endDate]);
  
  // Load more emails (pagination)
  const handleLoadMore = async () => {
    if (!lastVisible || !filters.businessId) return;
    
    try {
      setLoading(true);
      
      // Build query constraints
      const constraints = [
        where('businessId', '==', filters.businessId),
        orderBy('sentAt', 'desc'),
        startAfter(lastVisible),
        limit(20)
      ];
      
      // Add date range filters
      if (filters.startDate) {
        constraints.push(where('sentAt', '>=', Timestamp.fromDate(new Date(filters.startDate))));
      }
      
      if (filters.endDate) {
        // Add 1 day to include the entire end date
        const endDate = new Date(filters.endDate);
        endDate.setDate(endDate.getDate() + 1);
        constraints.push(where('sentAt', '<', Timestamp.fromDate(endDate)));
      }
      
      // Create and execute query
      const emailsRef = collection(db, 'emailLogs');
      const emailsQuery = query(emailsRef, ...constraints, limit(20));
      
      const querySnapshot = await getDocs(emailsQuery);
      
      if (!querySnapshot.empty) {
        const newLogs = [];
        
        querySnapshot.forEach(doc => {
          newLogs.push({
            id: doc.id,
            ...doc.data(),
            sentAt: doc.data().sentAt?.toDate() || null
          });
        });
        
        setEmailLogs(prev => [...prev, ...newLogs]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(newLogs.length === 20);
      } else {
        setHasMore(false);
      }
    } catch (err) {
      console.error("Error loading more emails:", err);
      setError("Failed to load more data. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Reset pagination when filters change
    setLastVisible(null);
  };
  
  // Format date for display
  const formatDate = (date) => {
    if (!date) return '-';
    
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  // Get filtered logs
  const getFilteredLogs = () => {
    if (!filters.searchTerm) return emailLogs;
    
    return emailLogs.filter(log => 
      log.subject.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
      log.recipients.to.some(to => to.toLowerCase().includes(filters.searchTerm.toLowerCase())) ||
      (log.senderName && log.senderName.toLowerCase().includes(filters.searchTerm.toLowerCase()))
    );
  };
  
  return (
    <div className="space-y-6">
      <h2 className="text-xl font-medium text-gray-900">Email Analytics</h2>
      
      {/* Error message */}
      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 text-red-700 flex items-start">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}
      
      {/* Filters */}
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="flex flex-wrap gap-4">
          <div className="flex-1 min-w-[200px]">
            <label htmlFor="businessId" className="block text-sm font-medium text-gray-700 mb-1">
              Business
            </label>
            <select
              id="businessId"
              name="businessId"
              value={filters.businessId}
              onChange={handleFilterChange}
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            >
              <option value="">All Businesses</option>
              {businessProfiles.map(profile => (
                <option key={profile.id} value={profile.id}>
                  {profile.name || 'Unnamed Business'}
                </option>
              ))}
            </select>
          </div>
          
          <div>
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-4 w-4 text-gray-400" />
              </div>
              <input
                id="startDate"
                name="startDate"
                type="date"
                value={filters.startDate}
                onChange={handleFilterChange}
                className="pl-10 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              />
            </div>
          </div>
          
          <div>
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
              End Date
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-4 w-4 text-gray-400" />
              </div>
              <input
                id="endDate"
                name="endDate"
                type="date"
                value={filters.endDate}
                onChange={handleFilterChange}
                className="pl-10 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              />
            </div>
          </div>
          
          <div className="flex items-end">
            <button
              type="button"
              onClick={() => {
                // Reset pagination
                setLastVisible(null);
                // Refresh with current filters
                setEmailLogs([]);
                setLoading(true);
              }}
              className="h-10 px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <RefreshCw className="h-4 w-4 mr-2" />
              Refresh
            </button>
          </div>
        </div>
      </div>
      
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white rounded-xl shadow-md p-6">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-gray-500 text-sm">Emails Sent</h3>
            <Mail className="h-5 w-5 text-blue-500" />
          </div>
          <p className="text-3xl font-bold">{stats.totalSent}</p>
          <p className="text-sm text-gray-500 mt-1">
            In selected time period
          </p>
        </div>
        
        // Continuing src/components/communication/EmailAnalytics.js

        <div className="bg-white rounded-xl shadow-md p-6">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-gray-500 text-sm">Open Rate</h3>
            <Eye className="h-5 w-5 text-green-500" />
          </div>
          <p className="text-3xl font-bold">{stats.openRate.toFixed(1)}%</p>
          <p className="text-sm text-gray-500 mt-1">
            {stats.totalOpened} of {stats.totalSent} emails opened
          </p>
        </div>
        
        <div className="bg-white rounded-xl shadow-md p-6">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-gray-500 text-sm">Click Rate</h3>
            <MousePointer className="h-5 w-5 text-purple-500" />
          </div>
          <p className="text-3xl font-bold">{stats.clickRate.toFixed(1)}%</p>
          <p className="text-sm text-gray-500 mt-1">
            {stats.totalClicked} of {stats.totalOpened} opened emails clicked
          </p>
        </div>
      </div>
      
      {/* Email Logs Table */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="text-lg font-medium text-gray-900">Email Activity</h3>
          
          <div className="flex items-center">
            <div className="relative mr-3">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
              <input
                type="text"
                placeholder="Search emails..."
                value={filters.searchTerm}
                onChange={(e) => setFilters(prev => ({ ...prev, searchTerm: e.target.value }))}
                className="pl-9 pr-4 py-2 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
              />
            </div>
            
            <button 
              className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-lg"
              onClick={() => {/* Handle download/export */}}
              title="Export data"
            >
              <Download size={18} />
            </button>
          </div>
        </div>
        
        {loading && emailLogs.length === 0 ? (
          <div className="flex justify-center items-center h-64">
            <Loader className="animate-spin h-8 w-8 text-[#190192]" />
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date & Time
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Subject
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Recipient
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sender
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {getFilteredLogs().map((log) => (
                    <tr key={log.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="flex items-center">
                          <Clock className="h-4 w-4 mr-1 text-gray-400" />
                          {formatDate(log.sentAt)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="max-w-xs truncate text-sm font-medium text-gray-900">{log.subject}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <div className="max-w-xs truncate">
                          {log.recipients.to[0]}
                          {log.recipients.to.length > 1 && ` +${log.recipients.to.length - 1} more`}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {log.senderName || log.senderEmail}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {log.opened ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Opened
                            {log.clicked && ' & Clicked'}
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                            Sent
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                  
                  {getFilteredLogs().length === 0 && (
                    <tr>
                      <td colSpan="5" className="px-6 py-10 text-center text-gray-500">
                        No email logs found for the selected filters.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
            {/* Load More Button */}
            {hasMore && (
              <div className="p-4 flex justify-center">
                <button
                  onClick={handleLoadMore}
                  disabled={loading}
                  className={`px-4 py-2 rounded-lg text-[#190192] border border-[#190192] hover:bg-purple-50 flex items-center ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {loading ? (
                    <Loader className="animate-spin h-4 w-4 mr-2" />
                  ) : (
                    <ChevronDown className="h-4 w-4 mr-2" />
                  )}
                  Load More
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EmailAnalytics;