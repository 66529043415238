// src/pages/tasks/TaskPage.js
import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { PlusCircle, CheckCircle, List, LayoutGrid } from 'lucide-react';
import TaskList from '../components/tasks/TaskList';
import TaskKanban from '../components/tasks/TaskKanban';

const TaskPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'kanban'

  // Check if we're on the main task list page
  const isMainPage = location.pathname === '/tasks' || location.pathname === '/tasks/';

  return (
    <div className="space-y-6">
      {/* Header */}
                        

            <div className="flex items-center space-x-3 justify-between">
            <p className="text-purple-800 text-sm ml-2">
                Track, assign, and manage tasks across your organization
              </p>
              {isMainPage && (
                <div className="bg-white bg-opacity-20 rounded-lg flex mr-3">
                  <button
                    onClick={() => setViewMode('list')}
                    className={`p-2 ${viewMode === 'list' ? 'bg-white text-[#190192]' : 'text-gray-400'} rounded-lg flex items-center`}
                  >
                    <List size={18} />
                  </button>
                  <button
                    onClick={() => setViewMode('kanban')}
                    className={`p-2 ${viewMode === 'kanban' ? 'bg-white text-[#190192]' : 'text-gray-400'} rounded-lg flex items-center`}
                  >
                    <LayoutGrid size={18} />
                  </button>
                </div>
              )}
              
      </div>

      {/* Content */}
      {isMainPage ? (
        viewMode === 'list' ? <TaskList /> : <TaskKanban />
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default TaskPage;