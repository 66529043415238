// src/components/communication/email/EmailSettingsRouter.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'lucide-react';
import { db } from '../../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import BusinessEmailSettings from './BusinessEmailSettings';

const EmailSettingsRouter = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const checkEmailSettings = async () => {
      try {
        // If 'new', redirect to profile creation form
        if (id === 'new') {
          navigate('/communication/email/profile/new');
          return;
        }
        
        // Get the business document
        const businessDocRef = doc(db, 'businesses', id);
        const businessDoc = await getDoc(businessDocRef);
        
        if (businessDoc.exists()) {
          const data = businessDoc.data();
          
          // Check if email settings exist and have required fields
          if (data.emailSettings && 
              data.emailSettings.senderName && 
              data.emailSettings.senderEmail) {
            // Email settings exist, continue to settings component
            setIsLoading(false);
          } else {
            // Email settings don't exist or are incomplete, redirect to profile form
            console.log("No email settings found, redirecting to profile form");
            navigate(`/communication/email/profile/${id}`);
          }
        } else {
          // Business doesn't exist, go to new profile form
          console.log("Business not found, redirecting to new profile form");
          navigate('/communication/email/profile/new');
        }
      } catch (error) {
        console.error("Error checking email settings:", error);
        setError(`Error loading settings: ${error.message}`);
        setIsLoading(false);
      }
    };
    
    checkEmailSettings();
  }, [id, navigate]);
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin h-8 w-8 text-[#190192]" />
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
        <p>{error}</p>
        <button 
          onClick={() => navigate('/communication/external')}
          className="mt-2 text-[#190192] hover:underline"
        >
          Return to Email Dashboard
        </button>
      </div>
    );
  }
  
  return <BusinessEmailSettings businessId={id} />;
};

export default EmailSettingsRouter;