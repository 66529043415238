// src/pages/records/RecordPage.js
import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { FileText, PlusCircle } from 'lucide-react';

const RecordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we're on the main records list page
  const isMainPage = location.pathname === '/records' || location.pathname === '/records/';

  return (
    <div className="space-y-6">
      {/* Header */}
    
        
          <div className="flex justify-between items-center">
            <div>
              <p className="text-purple-800 text-sm">
                Track activities, incidents, and transactions across your business
              </p>
            </div>

       
      </div>

      {/* Nested Routes */}
      <Outlet />
    </div>
  );
};

export default RecordPage;