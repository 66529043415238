// src/components/communication/DataSourceSelector.jsx
import React, { useRef, useEffect } from 'react';
import { 
  FileText, 
  ShoppingCart, 
  Package, 
  DollarSign,
  Users,
  BarChart,
  Briefcase,
  Truck
} from 'lucide-react';

const DataSourceSelector = ({ selected, onSelect, onClose, position = 'bottom' }) => {
  const ref = useRef(null);
  
  // Close when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const dataSourceOptions = [
    { value: 'sales', label: 'Sales', icon: <ShoppingCart className="h-4 w-4" /> },
    { value: 'inventory', label: 'Inventory', icon: <Package className="h-4 w-4" /> },
    { value: 'finances', label: 'Finances', icon: <DollarSign className="h-4 w-4" /> },
   { value: 'customers', label: 'Customers', icon: <Users className="h-4 w-4" /> },
   { value: 'orders', label: 'Orders', icon: <Truck className="h-4 w-4" /> },
   { value: 'marketing', label: 'Marketing', icon: <BarChart className="h-4 w-4" /> },
   { value: 'employees', label: 'Employees', icon: <Users className="h-4 w-4" /> },
   { value: 'business', label: 'Business Details', icon: <Briefcase className="h-4 w-4" /> }
 ];

 const toggleDataSource = (value) => {
   if (selected.includes(value)) {
     onSelect(selected.filter(item => item !== value));
   } else {
     onSelect([...selected, value]);
   }
 };

 return (
   <div 
     ref={ref}
     className="absolute z-10 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
     style={{ 
       right: 0, 
       ...(position === 'top' 
         ? { bottom: '100%', marginBottom: '4px' } 
         : { top: '100%', marginTop: '4px' })
     }}
   >
     <div className="py-1 max-h-60 overflow-y-auto">
       {dataSourceOptions.map((option) => (
         <button
           key={option.value}
           className="w-full text-left block px-4 py-2 text-sm hover:bg-gray-100 flex items-center justify-between"
           onClick={() => toggleDataSource(option.value)}
         >
           <div className="flex items-center">
             <span className={`mr-2 ${selected.includes(option.value) ? 'text-blue-500' : 'text-gray-500'}`}>
               {option.icon}
             </span>
             <span className={selected.includes(option.value) ? 'text-blue-700 font-medium' : 'text-gray-700'}>
               {option.label}
             </span>
           </div>
           
           {selected.includes(option.value) && (
             <div className="h-4 w-4 rounded-full bg-blue-500 flex items-center justify-center">
               <svg className="h-3 w-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
               </svg>
             </div>
           )}
         </button>
       ))}
     </div>
     
     <div className="py-1 px-3 border-t border-gray-100">
       <div className="flex justify-between">
         <button
           className="text-xs text-blue-600 hover:text-blue-800"
           onClick={() => onSelect(dataSourceOptions.map(option => option.value))}
         >
           Select all
         </button>
         <button
           className="text-xs text-gray-600 hover:text-gray-800"
           onClick={() => onSelect([])}
         >
           Clear all
         </button>
       </div>
     </div>
   </div>
 );
};

export default DataSourceSelector;