// src/components/business/BusinessEmailSettings.js
import React, { useState, useEffect } from 'react';
import { 
  Save, AlertCircle, Mail, CheckCircle, Loader,
  Info, Shield, RefreshCw
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const BusinessEmailSettings = ({ businessId }) => {
  const { currentUser } = useAuth();
  
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  const [emailSettings, setEmailSettings] = useState({
    senderName: '',
    senderEmail: '',
    replyToEmail: '',
    isVerified: false,
    verificationStatus: 'pending', // pending, verified, failed
    smtpSettings: {
      host: '',
      port: '',
      username: '',
      password: '',
      useSsl: true
    },
    defaultFooter: '',
    unsubscribeLink: true,
    maxDailyEmails: 100
  });
  
  // Load existing email settings
  useEffect(() => {
    const fetchEmailSettings = async () => {
      if (!businessId) return;
      
      try {
        setLoading(true);
        setError(null);
        
        const businessDoc = await getDoc(doc(db, 'businesses', businessId));
        
        if (businessDoc.exists()) {
          const data = businessDoc.data();
          
          if (data.emailSettings) {
            setEmailSettings(data.emailSettings);
          }
        }
      } catch (err) {
        console.error("Error loading email settings:", err);
        setError("Failed to load email settings. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    
    fetchEmailSettings();
  }, [businessId]);
  
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.startsWith('smtp.')) {
      const smtpField = name.split('.')[1];
      setEmailSettings(prev => ({
        ...prev,
        smtpSettings: {
          ...prev.smtpSettings,
          [smtpField]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setEmailSettings(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
    
    // Clear messages
    setError(null);
    setSuccess(null);
  };
  
  // Save email settings
  const handleSaveSettings = async () => {
    try {
      setSaving(true);
      setError(null);
      setSuccess(null);
      
      if (!emailSettings.senderEmail) {
        setError("Sender email is required");
        setSaving(false);
        return;
      }
      
      if (!emailSettings.senderName) {
        setError("Sender name is required");
        setSaving(false);
        return;
      }
      
      // Update business document with email settings
      await updateDoc(doc(db, 'businesses', businessId), {
        emailSettings: emailSettings
      });
      
      setSuccess("Email settings saved successfully");
    } catch (err) {
      console.error("Error saving email settings:", err);
      setError("Failed to save email settings: " + err.message);
    } finally {
      setSaving(false);
    }
  };
  
  // Verify sender email
  const handleVerifySender = async () => {
    try {
      setVerifying(true);
      setError(null);
      setSuccess(null);
      
      if (!emailSettings.senderEmail) {
        setError("Sender email is required");
        setVerifying(false);
        return;
      }
      
      // In a real app, this would call your backend API to trigger
      // a verification process with SendGrid or your email provider
      // For now, we'll simulate it
      
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Update verification status
      setEmailSettings(prev => ({
        ...prev,
        verificationStatus: 'verifying',
        verificationMessage: 'A verification email has been sent. Please check your inbox.'
      }));
      
      setSuccess("Verification email sent. Please check your inbox.");
      
      // In a real app, you would update this status when the email is actually verified
    } catch (err) {
      console.error("Error verifying sender:", err);
      setError("Failed to start verification: " + err.message);
    } finally {
      setVerifying(false);
    }
  };
  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin h-8 w-8 text-[#190192]" />
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl shadow-md p-6">
      <h2 className="text-xl font-medium text-gray-900 mb-6">Email Sending Settings</h2>
      
      {/* Messages */}
      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700 flex items-start">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}
      
      {success && (
        <div className="mb-6 bg-green-50 border-l-4 border-green-500 p-4 text-green-700 flex items-start">
          <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{success}</p>
        </div>
      )}
      
      <div className="space-y-6">
        {/* Sender Information */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Sender Details</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="senderName" className="block text-sm font-medium text-gray-700 mb-1">
                Sender Name <span className="text-red-500">*</span>
              </label>
              <input
                id="senderName"
                name="senderName"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={emailSettings.senderName}
                onChange={handleInputChange}
                placeholder="Your Business Name"
              />
              <p className="mt-1 text-xs text-gray-500">
                This name will appear as the sender of your emails
              </p>
            </div>
            
            <div>
              <label htmlFor="senderEmail" className="block text-sm font-medium text-gray-700 mb-1">
                Sender Email <span className="text-red-500">*</span>
              </label>
              <div className="flex">
                <input
                  id="senderEmail"
                  name="senderEmail"
                  type="email"
                  className="block w-full rounded-l-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={emailSettings.senderEmail}
                  onChange={handleInputChange}
                  placeholder="email@yourdomain.com"
                />
                <button
                  type="button"
                  onClick={handleVerifySender}
                  disabled={verifying || !emailSettings.senderEmail}
                  className={`px-4 py-2 rounded-r-lg text-white ${
                    verifying || !emailSettings.senderEmail ? 'bg-gray-400' : 'bg-[#190192] hover:bg-[#2C0DB5]'
                  } flex items-center`}
                >
                  {verifying ? (
                    <Loader className="animate-spin h-4 w-4 mr-1" />
                  ) : (
                    <RefreshCw className="h-4 w-4 mr-1" />
                  )}
                  Verify
                </button>
              </div>
              
              {/* Verification status */}
              {emailSettings.verificationStatus === 'verified' && (
                <p className="mt-1 text-xs text-green-600 flex items-center">
                  <CheckCircle className="h-3 w-3 mr-1" />
                  Verified
                </p>
              )}
              
              {emailSettings.verificationStatus === 'verifying' && (
                <p className="mt-1 text-xs text-blue-600 flex items-center">
                  <Info className="h-3 w-3 mr-1" />
                  {emailSettings.verificationMessage}
                </p>
              )}
              
              {emailSettings.verificationStatus === 'failed' && (
                <p className="mt-1 text-xs text-red-600 flex items-center">
                  <AlertCircle className="h-3 w-3 mr-1" />
                  Verification failed. Please try again.
                </p>
              )}
              
              <p className="mt-1 text-xs text-gray-500">
                Must be verified before sending emails
              </p>
            </div>
          </div>
          
          <div className="mt-4">
            <label htmlFor="replyToEmail" className="block text-sm font-medium text-gray-700 mb-1">
              Reply-To Email
            </label>
            <input
              id="replyToEmail"
              name="replyToEmail"
              type="email"
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={emailSettings.replyToEmail}
              onChange={handleInputChange}
              placeholder="replies@yourdomain.com"
            />
            <p className="mt-1 text-xs text-gray-500">
              If left empty, replies will go to the sender email
            </p>
          </div>
        </div>
        
        {/* Email Defaults */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Email Defaults</h3>
          
          <div>
            <label htmlFor="defaultFooter" className="block text-sm font-medium text-gray-700 mb-1">
              Default Email Footer
            </label>
            <textarea
              id="defaultFooter"
              name="defaultFooter"
              rows="4"
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={emailSettings.defaultFooter}
              onChange={handleInputChange}
              placeholder="Footer text to be included in all emails..."
            />
            <p className="mt-1 text-xs text-gray-500">
              This will be appended to all emails (HTML supported)
            </p>
          </div>
          
          <div className="mt-4 flex items-center">
            <input
              id="unsubscribeLink"
              name="unsubscribeLink"
              type="checkbox"
              className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
              checked={emailSettings.unsubscribeLink}
              onChange={handleInputChange}
            />
            <label htmlFor="unsubscribeLink" className="ml-2 block text-sm text-gray-700">
              Include unsubscribe link in marketing emails
            </label>
          </div>
          
          <div className="mt-4">
            <label htmlFor="maxDailyEmails" className="block text-sm font-medium text-gray-700 mb-1">
              Daily Email Limit
            </label>
            <input
              id="maxDailyEmails"
              name="maxDailyEmails"
              type="number"
              min="0"
              className="block w-48 rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={emailSettings.maxDailyEmails}
              onChange={handleInputChange}
            />
            <p className="mt-1 text-xs text-gray-500">
              Maximum number of emails to send per day (0 = unlimited)
            </p>
          </div>
        </div>
        
        {/* Advanced SMTP Settings */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Advanced SMTP Settings (Optional)</h3>
          <p className="text-sm text-gray-600 mb-4">
            <Info className="h-4 w-4 inline mr-1 text-blue-500" />
            Use these settings only if you want to use your own SMTP server instead of our email service.
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="smtp.host" className="block text-sm font-medium text-gray-700 mb-1">
                SMTP Host
              </label>
              <input
                id="smtp.host"
                name="smtp.host"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={emailSettings.smtpSettings.host}
                onChange={handleInputChange}
                placeholder="smtp.yourdomain.com"
              />
            </div>
            
            <div>
              <label htmlFor="smtp.port" className="block text-sm font-medium text-gray-700 mb-1">
                SMTP Port
              </label>
              <input
                id="smtp.port"
                name="smtp.port"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={emailSettings.smtpSettings.port}
                onChange={handleInputChange}
                placeholder="587 or 465"
              />
            </div>
            
            <div>
              <label htmlFor="smtp.username" className="block text-sm font-medium text-gray-700 mb-1">
                SMTP Username
              </label>
              <input
                id="smtp.username"
                name="smtp.username"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={emailSettings.smtpSettings.username}
                onChange={handleInputChange}
                placeholder="username@yourdomain.com"
              />
            </div>
            
            <div>
              <label htmlFor="smtp.password" className="block text-sm font-medium text-gray-700 mb-1">
                SMTP Password
              </label>
              <input
                id="smtp.password"
                name="smtp.password"
                type="password"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={emailSettings.smtpSettings.password}
                onChange={handleInputChange}
                placeholder="••••••••"
              />
            </div>
          </div>
          
          <div className="mt-4 flex items-center">
            <input
              id="smtp.useSsl"
              name="smtp.useSsl"
              type="checkbox"
              className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
              checked={emailSettings.smtpSettings.useSsl}
              onChange={handleInputChange}
            />
            <label htmlFor="smtp.useSsl" className="ml-2 block text-sm text-gray-700">
              Use SSL/TLS
            </label>
          </div>
          
          <div className="mt-4 bg-blue-50 p-4 rounded-lg border border-blue-100">
            <div className="flex items-start">
              <Shield className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
              <p className="text-sm text-blue-700">
                Your SMTP password is encrypted before being stored and is never visible to our team.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Save Button */}
      <div className="mt-8 flex justify-end">
        <button
          type="button"
          onClick={handleSaveSettings}
          disabled={saving}
          className={`px-6 py-2.5 rounded-lg text-white ${
            saving ? 'bg-gray-400' : 'bg-[#190192] hover:bg-[#2C0DB5]'
          } flex items-center`}
        >
          {saving ? (
            <>
              <Loader className="animate-spin h-4 w-4 mr-2" />
              Saving...
            </>
          ) : (
            <>
              <Save className="h-4 w-4 mr-2" />
              Save Settings
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default BusinessEmailSettings;