// src/components/stock/StockItemList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, Tag, 
  DollarSign, Package, AlertTriangle, Download 
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';
import { db } from '../../../firebase/config';

const StockItemList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [stockItems, setStockItems] = useState([]);
  const { formatAmount } = useCurrency(); // Use currency formatting from currencyUtils

  // Fetch stock items
  useEffect(() => {
    const fetchStockItems = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock stock items data
        const mockData = [
          {
            id: '1',
            sku: 'TS-001',
            name: 'T-Shirt Large',
            category: 'Apparel',
            type: 'physical',
            costPrice: 8.50,
            sellingPrice: 19.99,
            minStockLevel: 10,
            reorderPoint: 15,
            locations: {
              'main-store': 15,
              'warehouse': 10
            },
            reservedQuantity: 2,
            imageUrl: null
          },
          {
            id: '2',
            sku: 'MG-100',
            name: 'Coffee Mug',
            category: 'Gifts',
            type: 'physical',
            costPrice: 5.75,
            sellingPrice: 12.99,
            minStockLevel: 12,
            reorderPoint: 20,
            locations: {
              'main-store': 22,
              'warehouse': 20
            },
            reservedQuantity: 0,
            imageUrl: null
          },
          {
            id: '3',
            sku: 'DL-050',
            name: 'E-book Bundle',
            category: 'Digital Products',
            type: 'digital',
            costPrice: 10.00,
            sellingPrice: 24.99,
            minStockLevel: 0,
            reorderPoint: 0,
            locations: {},
            reservedQuantity: 0,
            isDigitalDelivery: true,
            imageUrl: null
          },
          {
            id: '4',
            sku: 'RM-201',
            name: 'Cotton Fabric',
            category: 'Raw Materials',
            type: 'raw_material',
            costPrice: 4.25,
            sellingPrice: 8.50,
            minStockLevel: 50,
            reorderPoint: 100,
            locations: {
              'warehouse': 156
            },
            reservedQuantity: 0,
            unitOfMeasure: 'yard',
            imageUrl: null
          },
          {
            id: '5',
            sku: 'TS-002',
            name: 'T-Shirt Medium',
            category: 'Apparel',
            type: 'physical',
            costPrice: 8.50,
            sellingPrice: 19.99,
            minStockLevel: 10,
            reorderPoint: 15,
            locations: {
              'main-store': 5,
              'warehouse': 3
            },
            reservedQuantity: 1,
            imageUrl: null
          }
        ];
        
        setStockItems(mockData);
      } catch (error) {
        console.error('Error fetching stock items:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchStockItems();
  }, []);

  // Get filtered stock items
  const getFilteredStockItems = () => {
    let filtered = stockItems;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(item => 
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by category
    if (filterCategory !== 'all') {
      filtered = filtered.filter(item => item.category === filterCategory);
    }
    
    return filtered;
  };

  // Calculate total stock for an item across all locations
  const getTotalStock = (item) => {
    if (item.type === 'digital' || item.type === 'service') {
      return '∞';
    }
    
    let total = 0;
    Object.values(item.locations || {}).forEach(qty => {
      total += qty;
    });
    
    return total;
  };

  // Check if an item is low on stock
  const isLowStock = (item) => {
    if (item.type === 'digital' || item.type === 'service') {
      return false;
    }
    
    const totalStock = getTotalStock(item);
    return totalStock !== '∞' && totalStock <= item.minStockLevel;
  };

  // Get unique categories from stock items
  const categories = ['all', ...new Set(stockItems.map(item => item.category))];

  return (
    <div className="p-6 bg-white overflow-auto">
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6 ">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search by name, SKU, or category..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            {categories.filter(c => c !== 'all').map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          
          <Link
            to="/inventory/items/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Add Item
          </Link>
          
          <button className="p-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50">
            <Download size={18} />
          </button>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {stockItems.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Package size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No stock items found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't added any inventory items yet. Click the 'Add Item' button to get started.
              </p>
              <Link
                to="/stock/items/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Add Your First Item
              </Link>
            </div>
          ) : getFilteredStockItems().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Package size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching items</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any items matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterCategory('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Stock Items Table */
            <div className="bg-white rounded-xl shadow-md overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      SKU
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Category
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      In Stock
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cost
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {getFilteredStockItems().map((item) => (
                    <tr key={item.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.sku}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {isLowStock(item) && (
                            <AlertTriangle size={16} className="text-amber-500 mr-2 flex-shrink-0" />
                          )}
                          <span className="text-sm text-gray-900">{item.name}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2.5 py-0.5 inline-flex text-xs leading-5 font-medium rounded-full bg-gray-100 text-gray-800">
                          {item.category}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-right">
                        <span className={isLowStock(item) ? 'text-amber-600 font-bold' : ''}>
                          {getTotalStock(item)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-right">
                        {formatAmount(item.costPrice)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-right">
                        {formatAmount(item.sellingPrice)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/stock/items/${item.id}`}
                          className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                        >
                          View <ChevronRight size={16} className="ml-1" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StockItemList;